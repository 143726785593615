import React from 'react';
import {
  initialState,
  PriceListWithProductsAction,
  priceListWithProductsReducer,
  PriceListWithProductsState,
} from '../../reducers/priceListWithProducts';

type PriceListWithProductsContextProps = {
  state: PriceListWithProductsState;
  dispatch: React.Dispatch<PriceListWithProductsAction>;
};

const PriceListWithProductsContext = React.createContext<PriceListWithProductsContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export function PriceListWithProductsProvider(props: React.PropsWithChildren<Record<string, unknown>>) {
  const [state, dispatch] = React.useReducer(priceListWithProductsReducer, initialState);
  return <PriceListWithProductsContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function usePriceListWithProducts() {
  const context = React.useContext(PriceListWithProductsContext);
  if (!context) {
    throw new Error(`usePriceListWithProducts must be used within an PriceListWithProductsProvider`);
  }
  return context;
}

import { useEffect, useState } from 'react';
import { getPriceProducts } from '../../apis/PriceApi';
import { PriceCategoryListProvider } from '../../context/prices/usePriceListCategories';
import ErrorData from '../general/states/ErrorData';
import Loading from '../general/states/Loading';
import NoData from '../general/states/NoData';
import PriceEditNameDescCard from './PriceEditNameDescCard';
import PriceEditRuleContainer from './PriceEditRuleContainer';
import PriceEditTopInfo from './PriceEditTopInfo';
import usePriceProduct from '../../context/prices/usePriceProduct';
import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';

const PriceEditContainer = (props: { id: number }) => {
  const [saveEditedRules, setSaveEditedRules] = useState<PriceProductDTO>({
    id: 0,
    category: { id: 0, name: { fi: '' } },
    description: { fi: '' },
    modifiedAt: '',
    modifiedByName: '',
    modifiedByUUID: '',
    name: { fi: '' },
    ordinalInCategory: 0,
    ruleCategories: [
      {
        id: 0,
        name: { fi: '' },
        rules: [
          {
            id: 0,
            amount: 0,
            application: 'FIXED_AMOUNT',
            approved: false,
            approvedAt: '',
            approvedByName: '',
            createdAt: '',
            createdByName: '',
            createdByUUID: '',
            currency: 'EUR',
            deliveryTime: 0,
            modifiedAt: '',
            modifiedByName: '',
            modifiedByUUID: '',
            priceRangeId: 0,
            reviewComment: '',
            target: 'ORDER',
            validFrom: '',
            validTo: '',
            vatBase: 'VAT_0',
          },
        ],
      },
    ],
  });

  const {
    state: { priceProduct, loading, error },
    dispatch,
  } = usePriceProduct();
  async function fetchPriceProduct() {
    dispatch({ type: 'FETCH_PRICE_PRODUCT_BEGIN' });
    try {
      const payload = await getPriceProducts(props.id);
      setSaveEditedRules(payload.data);
      dispatch({
        type: 'FETCH_PRICE_PRODUCT_SUCCESS',
        payload: { priceProduct: payload.data },
      });
    } catch (error) {
      dispatch({ type: 'FETCH_PRICE_PRODUCT_ERROR', error: 'todo-error-message-from-server' });
    }
  }
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      fetchPriceProduct();
    }
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (priceProduct === null) {
    return <NoData />;
  }
  if (error) {
    return <ErrorData error={error} />;
  }
  return (
    <div>
      <PriceEditTopInfo priceProduct={priceProduct} />
      <PriceCategoryListProvider>
        <PriceEditNameDescCard saveEditedRules={saveEditedRules} setSaveEditedRules={setSaveEditedRules} />
        <PriceEditRuleContainer
          priceProduct={priceProduct}
          saveEditedRules={saveEditedRules}
          setSaveEditedRules={setSaveEditedRules}
        />
      </PriceCategoryListProvider>
    </div>
  );
};
export default PriceEditContainer;

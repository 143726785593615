import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';

import { useTranslation } from 'react-i18next';
import PriceEditCurrentRulesRange from './PriceEditCurrentRulesRange';
import { PriceRangeProvider } from '../../context/prices/usePriceRanges';
import countVat from '@tiketti-team/tp-types-and-utils/utils/vat/vat';
import { Dispatch, SetStateAction } from 'react';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
const PriceEditCurrentRules = (props: SaveEditedPriceProducts) => {
  const { t } = useTranslation();

  return (
    <div>
      {props.saveEditedRules.ruleCategories.map((ruleCategories) =>
        ruleCategories.rules
          .filter((rule) => {
            return rule.createdAt !== '';
          })
          .map((rule) => (
            <div key={rule.id}>
              <div className='mb-4'>
                <div className='edit-rule-card lg:w-full'>
                  <div className='edit-rule-content mb-3 ml-2 p-1 mt-3'>
                    <div className='edit-rule-container-rules lg:col-span-2'>
                      <div className='flex flex-row'>
                        <p className='edit-rule-price-header'>{`${t(
                          `prices.ruleCategory.name.fi.${ruleCategories.name.fi}`
                        )}`}</p>
                      </div>
                      <PriceRangeProvider>
                        <PriceEditCurrentRulesRange key={rule.id} rules={rule} />
                      </PriceRangeProvider>
                    </div>
                    <div className='edit-rule-price-container xxs:w-full xxs:mb-5 col-span-3'>
                      <p className='edit-rule-price-header'>{t('prices.priceDetail.priceCategoryRuleLabel')}</p>
                      <div className='edit-rules-input-headers-rules smaller-screens-hidden-text'>
                        <p className='lg:col-span-2 text-sm mb-2'>{t('prices.priceDetail.priceRulesLabel')}</p>
                        <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
                        <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
                      </div>
                      <div className='edit-rules-input-container-rules relative'>
                        <div className='lg:col-span-2'>
                          <div className='edit-rules-visible-small-screen'>
                            <p>{t('prices.priceDetail.priceRulesLabel')}</p>
                          </div>
                          <div className='edit-rule-price-current-input-rules'>
                            <div className='flex flex-row relative'>
                              <p className='px-2'>{`${t(`prices.priceRules.application.${rule.application}`)} / ${t(
                                `prices.priceRules.target.${rule.target}`
                              )}`}</p>
                            </div>
                          </div>
                        </div>
                        <div className='edit-rules-visible-small-screen'>
                          <p>{t('prices.priceDetail.amountLabel')}</p>
                        </div>
                        <div className='flex flex-row relative'>
                          <div className='edit-rule-price-current-input-rules'>
                            <div className='flex flex-row relative'>
                              <p className='px-2'>{`${rule.amount}`}</p>
                              <p className='absolute right-0 text-gray-400'>€</p>
                            </div>
                          </div>
                        </div>
                        <div className='edit-rules-visible-small-screen'>
                          <p>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
                        </div>

                        <div className='flex flex-row relative'>
                          <div className='edit-rule-price-current-input-rules'>
                            <div className='flex flex-row relative'>
                              <p className='px-1'>{countVat(rule.amount, rule.vatBase)}</p>

                              <p className='absolute right-0 text-gray-400'>€</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-span-2 w-full p-1'>
                        <p className=' text-gray-400 p-3 ml-1 text-sm'>{`${t(
                          `prices.priceRules.vatBase.${rule.vatBase}`
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
      )}
    </div>
  );
};
export default PriceEditCurrentRules;

import { PriceProductRuleDTO, PriceRangeDTO } from '@tiketti-team/tp-types-and-utils/types';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPriceRangeById } from '../../apis/PriceApi';
import usePriceRanges from '../../context/prices/usePriceRanges';
import ErrorData from '../general/states/ErrorData';
import Loading from '../general/states/Loading';

interface SaveEditedRange {
  setSaveEditedRange: Dispatch<SetStateAction<PriceRangeDTO>>;
  saveEditedRange: {
    id: number;
    maxAmount: number;
    minAmount: number;
  };
}
interface ValidationError {
  setIsValidationError: Dispatch<SetStateAction<boolean>>;
  isValidationError: boolean;
}
// --> TYPES-UTILS
interface ErrorMessages {
  invalidNumber: string;
  maxLesserThanMin: string;
  minGreaterThanMax: string;
  zeroValueError: string;
}
interface ValidationResult {
  updatedAmount?: number;
  error?: string;
}
// --> TYPES-UTILS
function convertCentsToPrice(priceInCents: number): string {
  const priceInEuros = priceInCents / 100;
  return priceInEuros.toFixed(2);
}
// TYPES-UTILS
function calculatePriceToCents(price: number): number {
  const formattedPrice = price.toString().replace(',', '.');
  const parsedPrice = parseFloat(formattedPrice);

  if (isNaN(parsedPrice)) {
    return 0;
  }
  const priceInCents = Math.floor(parsedPrice * 100);
  return priceInCents;
}
// --> TYPES-UTILS
function validatePriceRange(
  value: string,
  field: 'minAmount' | 'maxAmount',
  id: number | null,
  prevState: { minAmount: number; maxAmount: number },
  currentMinAmount: number,
  currentMaxAmount: number,
  errorMessages: ErrorMessages
): ValidationResult {
  if ((id && value === '0') || value === '0.00' || value === '0,00' || value === '') {
    const errorMessage = errorMessages.zeroValueError;
    return { error: errorMessage };
  }
  if (!/^\d+(?:[.,]\d{1,2})?$/.test(value)) {
    return { error: errorMessages.invalidNumber };
  }
  const numericValue = calculatePriceToCents(parseFloat(value.replace(',', '.')));
  if (isNaN(numericValue)) {
    return { error: errorMessages.invalidNumber };
  }

  if (field === 'minAmount') {
    if (numericValue > currentMaxAmount || numericValue > prevState.maxAmount) {
      return { error: errorMessages.minGreaterThanMax };
    } else if (prevState.maxAmount < currentMinAmount || prevState.maxAmount < numericValue) {
      return { error: errorMessages.maxLesserThanMin };
    }
  } else if (field === 'maxAmount') {
    if (numericValue < currentMinAmount || numericValue < prevState.minAmount) {
      return { error: errorMessages.maxLesserThanMin };
    } else if (prevState.minAmount > currentMaxAmount || prevState.minAmount > numericValue) {
      return { error: errorMessages.minGreaterThanMax };
    }
  }

  return { updatedAmount: numericValue };
}
const PriceEditScheduleCurrentRange = (props: SaveEditedRange & ValidationError & { rules: PriceProductRuleDTO }) => {
  const { t } = useTranslation();
  const formErrorRef = useRef('');
  const [minAmountInputValue, setMinAmountInputValue] = useState('');
  const [maxAmountInputValue, setMaxAmountInputValue] = useState('');

  const {
    state: { priceranges, loading, error },
    dispatch,
  } = usePriceRanges();

  useEffect(() => {
    let ignore = false;
    async function fetchPriceRanges() {
      dispatch({ type: 'FETCH_PRICE_RANGE_BEGIN' });
      try {
        const payload = await getPriceRangeById(props.rules.priceRangeId);
        props.setSaveEditedRange(payload.data);
        setMinAmountInputValue(convertCentsToPrice(payload.data.minAmount));
        setMaxAmountInputValue(convertCentsToPrice(payload.data.maxAmount));
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_RANGE_SUCCESS', payload: { priceranges: payload.data } });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_RANGE_ERROR', error: 'todo-error-message-from-server' });
        }
      }
    }

    fetchPriceRanges();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (priceranges === null) {
    return (
      <div className='edit-rule-range-container-rules'>
        <p className='ml-1 mb-2 text-sm'>{t('prices.priceRange.min')}</p>
        <p className='ml-1 text-sm '>{t('prices.priceRange.max')}</p>
        <div className='edit-rule-range-input-container-rules'>
          <div className='edit-rule-range-input-item-rules relative'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input className='edit-rule-range-input-rules'></input>
          </div>
          <p className='flex justify-center mt-2 sm:ml-7 xxs:ml-5'>-</p>
          <div className='edit-rule-range-input-item-rules relative'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input className='edit-rule-range-input-rules'></input>
          </div>
        </div>
      </div>
    );
  }
  if (error) {
    return <ErrorData error={error} />;
  }

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>, id: number, field: 'minAmount' | 'maxAmount') => {
    const newValue = e.target.value.trim();

    if (field === 'minAmount') {
      setMinAmountInputValue(newValue);
    } else if (field === 'maxAmount') {
      setMaxAmountInputValue(newValue);
    }

    const errorMessages: ErrorMessages = {
      invalidNumber: t(`validate.invalidnumber`),
      maxLesserThanMin: t(`validate.maxLesserThanMin`),
      minGreaterThanMax: t(`validate.minGreaterThanMax`),
      zeroValueError: t('validate.notEmpty'),
    };

    props.setSaveEditedRange((prevState) => {
      if (prevState.id === id) {
        const parsedValue = parseFloat(newValue.replace(',', '.'));
        const updatedAmount = parsedValue;
        const currentMinAmount = field === 'minAmount' ? calculatePriceToCents(updatedAmount) : prevState.minAmount;
        const currentMaxAmount = field === 'maxAmount' ? calculatePriceToCents(updatedAmount) : prevState.maxAmount;

        const validation = validatePriceRange(
          newValue,
          field,
          id,
          prevState,
          currentMinAmount,
          currentMaxAmount,
          errorMessages
        );

        if (validation.error) {
          formErrorRef.current = validation.error;
          props.setIsValidationError(true);
        } else {
          formErrorRef.current = '';
          props.setIsValidationError(false);
        }

        const updatedPriceRange = {
          ...prevState,
          minAmount: field === 'minAmount' ? currentMinAmount : prevState.minAmount,
          maxAmount: field === 'maxAmount' ? currentMaxAmount : prevState.maxAmount,
        };

        return updatedPriceRange;
      }

      return prevState;
    });
  };

  return (
    <div>
      {formErrorRef.current && <p className='text-red-500 p-1 ml-1 text-sm'>{formErrorRef.current}</p>}
      <div className='edit-rule-range-container-rules'>
        <p className='ml-1 mb-2 text-sm'>{t('prices.priceRange.max')}</p>
        <p className='ml-1 text-sm '>{t('prices.priceRange.max')}</p>
        <div className='edit-rule-range-input-container-rules'>
          <div className='edit-rule-range-input-item-rules relative '>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input
              className='edit-rule-range-input-rules appearance-none'
              type='text'
              name='minAmount'
              value={minAmountInputValue}
              onChange={(e) => handleChangeAmount(e, props.saveEditedRange.id, 'minAmount')}
            />
          </div>
          <p className='flex justify-center mt-2 sm:ml-7 xxs:ml-5'>-</p>
          <div className='edit-rule-range-input-item-rules relative'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input
              className='edit-rule-range-input-rules appearance-none'
              name='maxAmount'
              type='text'
              value={maxAmountInputValue}
              onChange={(e) => handleChangeAmount(e, props.saveEditedRange.id, 'maxAmount')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceEditScheduleCurrentRange;

import React from 'react';
import {
  priceProductRuleTargetsAndApplicationReducer,
  initialState,
  PriceProductRuleTargetsAndApplicationAction,
  PriceProductRuleTargetsAndApplicationState,
} from '../../reducers/priceProductRuleTargetsAndApplications';

type PriceProductRuleTargetsAndApplicationContextProps = {
  state: PriceProductRuleTargetsAndApplicationState;
  dispatch: React.Dispatch<PriceProductRuleTargetsAndApplicationAction>;
};
const PriceProductRuleTargetsAndApplicationContext =
  React.createContext<PriceProductRuleTargetsAndApplicationContextProps>({
    state: initialState,
    dispatch: () => initialState,
  });

export function PriceProductRuleTargetsAndApplicationProvider(props: React.PropsWithChildren<Record<string, unknown>>) {
  const [state, dispatch] = React.useReducer(priceProductRuleTargetsAndApplicationReducer, initialState);
  return <PriceProductRuleTargetsAndApplicationContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function usePriceProductRuleTargetsAndApplication() {
  const context = React.useContext(PriceProductRuleTargetsAndApplicationContext);
  if (!context) {
    throw new Error(
      `usePriceProductRuleTargetsAndApplication must be used within an PriceProductRuleTargetsAndApplicationProvider`
    );
  }
  return context;
}

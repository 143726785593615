import { useLocation } from 'react-router-dom';
import PriceEditContainer from '../../components/prices/PriceEditContainer';
import { PriceProductProvider } from '../../context/prices/usePriceProduct';

const PriceEditPage = () => {
  const location = useLocation();
  const id: number = location.state.id;
  return (
    <div>
      <PriceProductProvider>
        <PriceEditContainer id={id} />
      </PriceProductProvider>
    </div>
  );
};
export default PriceEditPage;

import { Transition, Dialog } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from '@heroicons/react/24/solid';
import {
  format,
  parse,
  eachDayOfInterval,
  endOfMonth,
  add,
  isEqual,
  isSameMonth,
  isToday,
  startOfISOWeek,
  endOfISOWeek,
} from 'date-fns';
import { fi } from 'date-fns/locale';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import classNamesStrBoolNum from '@tiketti-team/tp-types-and-utils/utils/concatenate-css-classes/class-names-str-bool-num';

interface SetModifiedAtInput {
  setModifiedAtInput: Dispatch<SetStateAction<{ date: string; hour: string; minute: string; second: string }>>;
}
interface ModifiedAtInput {
  modifiedAtInput: string;
}

const PriceEditSaveChangesDatepicker = (props: SetModifiedAtInput & ModifiedAtInput) => {
  const today = new Date();
  const [open, setOpen] = useState(false);
  const selectedDay = today;
  const [currentMonth, setCurrentMonth] = useState(format(new Date(today), 'MMM-yyyy'));
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
  const newDays = eachDayOfInterval({
    start: startOfISOWeek(firstDayCurrentMonth),
    end: endOfISOWeek(endOfMonth(firstDayCurrentMonth)),
  });
  function nextMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(new Date(firstDayNextMonth), 'MMM-yyyy'));
  }
  function previousMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(new Date(firstDayNextMonth), 'MMM-yyyy'));
  }

  return (
    <div>
      <button
        onClick={() => {
          setOpen(true);
        }}
      >
        <CalendarIcon className='w-5 h-5 absolute inset-y-1.5 right-0 flex items-center mr-4' />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          <div className='fixed lg:inset-0 xxs:inset-x-0 xxs:inset-y-[200px]'>
            <div className='flex min-h-full items-end justify-center text-center sm:items-center sm:p-0 '>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative h-[350px] w-[350px] px-1 pt-5 pb-4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                  <div className='text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-3 xl:col-start-9'>
                    <div className='flex items-center text-gray-900 px-3'>
                      <button
                        type='button'
                        onClick={previousMonth}
                        className='-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-black border-none'
                      >
                        <span className='sr-only'>Previous month</span>
                        <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                      </button>
                      <div className='flex-auto text-sm font-semibold'>
                        {format(new Date(firstDayCurrentMonth), 'MMM yyyy', { locale: fi })}
                      </div>
                      <button
                        onClick={nextMonth}
                        type='button'
                        className='-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-black border-none'
                      >
                        <span className='sr-only'>Next month</span>
                        <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                      </button>
                    </div>
                    <div className='mt-6 grid grid-cols-7 text-sm leading-6 text-blue-500 font-bold'>
                      <div>M</div>
                      <div>T</div>
                      <div>K</div>
                      <div>T</div>
                      <div>P</div>
                      <div>L</div>
                      <div>S</div>
                    </div>
                    <div className='grid grid-cols-7 mt-4 text-sm h-full'>
                      {newDays.map((day, dayIdx) => (
                        <div
                          key={day.toString()}
                          className={classNamesStrBoolNum(dayIdx > 6 && 'border-t border-gray-200 ')}
                        >
                          <button
                            type='button'
                            name='date'
                            value={props.modifiedAtInput.toString()}
                            onClick={() => {
                              props.setModifiedAtInput(
                                (prevState: { date: string; hour: string; minute: string; second: string }) => ({
                                  ...prevState,
                                  date: format(new Date(day), 'dd.MM.yyyy').toString(),
                                })
                              );
                              setOpen(false);
                            }}
                            className={classNamesStrBoolNum(
                              isEqual(day, selectedDay),
                              !isEqual(day, selectedDay) && isToday(day) && 'text-blue-600 font-semibold',
                              !isEqual(day, selectedDay) &&
                                !isToday(day) &&
                                !isSameMonth(day, firstDayCurrentMonth) &&
                                'text-gray-400',
                              !isEqual(day, selectedDay) &&
                                'py-1.5 hover:bg-blue-600 hover:text-white hover:rounded-full focus:z-10 border-none w-8 h-8',
                              dayIdx === 0 && 'rounded-full',
                              dayIdx === 6 && 'rounded-full',
                              dayIdx === day.toString().length - 7 && 'rounded-full',
                              dayIdx === day.toString().length - 1 && 'rounded-full'
                            )}
                          >
                            <time>{format(new Date(day), 'd')}</time>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
export default PriceEditSaveChangesDatepicker;

import { PriceProductCategoryDTO } from '@tiketti-team/tp-types-and-utils/types';

export type PriceCategoryListAction =
  | { type: 'FETCH_PRICE_LIST_CATEGORY_BEGIN' }
  | {
      type: 'FETCH_PRICE_LIST_CATEGORY_SUCCESS';
      payload: { categorylists: Array<PriceProductCategoryDTO> };
    }
  | { type: 'FETCH_PRICE_LIST_CATEGORY_ERROR'; error: string };

export interface PriceCategoryListState {
  categorylists: Array<PriceProductCategoryDTO>;
  loading: boolean;
  error: string | null;
  initial: boolean;
}

export const initialState: PriceCategoryListState = {
  categorylists: [],
  loading: false,
  error: null,
  initial: true,
};

export function priceCategoryListReducer(
  state: PriceCategoryListState,
  action: PriceCategoryListAction
): PriceCategoryListState {
  switch (action.type) {
    case 'FETCH_PRICE_LIST_CATEGORY_BEGIN':
      return {
        ...state,
        loading: true,
        error: null,
        initial: false,
      };
    case 'FETCH_PRICE_LIST_CATEGORY_SUCCESS':
      return {
        ...state,
        loading: false,
        initial: false,
        categorylists: action.payload.categorylists,
      };
    case 'FETCH_PRICE_LIST_CATEGORY_ERROR':
      return {
        ...state,
        loading: false,
        initial: false,
        error: action.error,
        categorylists: [],
      };
    default:
      return state;
  }
}

import { TagIcon } from '@heroicons/react/24/solid';
import { PriceProductRuleDTO } from '@tiketti-team/tp-types-and-utils/types';
import { t } from 'i18next';
import { useEffect } from 'react';
import { getPriceRangeById } from '../../apis/PriceApi';
import usePriceRanges from '../../context/prices/usePriceRanges';
import ErrorData from '../general/states/ErrorData';
import Loading from '../general/states/Loading';
// --> TYPES-UTILS
function convertCentsToPrice(priceInCents: number): string {
  const priceInEuros = priceInCents / 100;
  return priceInEuros.toFixed(2);
}

const PriceListDropRulePriceRange = (props: { rules: PriceProductRuleDTO }) => {
  const {
    state: { priceranges, loading, error },
    dispatch,
  } = usePriceRanges();

  useEffect(() => {
    let ignore = false;
    async function fetchPriceRanges() {
      dispatch({ type: 'FETCH_PRICE_RANGE_BEGIN' });
      try {
        const payload = await getPriceRangeById(props.rules.priceRangeId);

        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_RANGE_SUCCESS', payload: { priceranges: payload.data } });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_RANGE_ERROR', error: 'todo-error-message-from-server' });
        }
      }
    }
    fetchPriceRanges();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (priceranges === null) {
    return (
      <p className='price-range flex flex-row'>
        <TagIcon className='small-icon mr-2' />
        {`0 € - 0 €`}
      </p>
    );
  }
  if (error) {
    return <ErrorData error={error} />;
  }

  return (
    <div>
      <p className='price-range flex flex-row'>
        <TagIcon className='small-icon mr-2' />
        {`${convertCentsToPrice(priceranges.minAmount)} ${t('prices.priceRules.currency.EUR')} - ${convertCentsToPrice(
          priceranges.maxAmount
        )}${t('prices.priceRules.currency.EUR')}`}
      </p>
    </div>
  );
};
export default PriceListDropRulePriceRange;

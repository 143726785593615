import { PriceListDTO } from '@tiketti-team/tp-types-and-utils/types';
import NoData from '../../components/general/states/NoData';
import Loading from '../../components/general/states/Loading';
import ErrorData from '../../components/general/states/ErrorData';
import { getPriceListWithPriceProducts } from '../../apis/PriceApi';
import usePriceListWithProducts from '../../context/prices/usePriceListWithProducts';
import { useEffect } from 'react';
import PriceListItemDropDown from './PriceListItemDropDown';

const PriceListItemCard = (props: { priceList: PriceListDTO }) => {
  const {
    state: { pricelistWithProducts, loading, error },
    dispatch,
  } = usePriceListWithProducts();

  useEffect(() => {
    let ignore = false;
    async function fetchPriceListWithProducts() {
      dispatch({ type: 'FETCH_PRICE_LIST_WITH_PRODUCTS_BEGIN' });
      try {
        const payload = await getPriceListWithPriceProducts(props.priceList.id);
        if (!ignore) {
          dispatch({
            type: 'FETCH_PRICE_LIST_WITH_PRODUCTS_SUCCESS',
            payload: { pricelistWithProducts: payload.data },
          });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_LIST_WITH_PRODUCTS_ERROR', error: 'todo-error-message-from-server' });
        }
      }
    }
    fetchPriceListWithProducts();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (pricelistWithProducts === null) {
    return <NoData />;
  }
  if (error) {
    return <ErrorData error={error} />;
  }

  return (
    <div>
      {pricelistWithProducts.priceProducts.map((item) => (
        <PriceListItemDropDown key={item.id} priceProducts={item} id={props.priceList.id} />
      ))}
    </div>
  );
};

export default PriceListItemCard;

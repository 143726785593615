import EditorInfo from './EditorInfo';
import './topinfo.css';

const TopInfo = (props: { modifiedByName: string | null; modifiedAt: string | null }) => {
  return (
    <div className='top-info'>
      {props.modifiedAt !== null && props.modifiedByName !== null && (
        <EditorInfo modifiedByName={props.modifiedByName} modifiedAt={props.modifiedAt} />
      )}
    </div>
  );
};
export default TopInfo;

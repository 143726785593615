import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';
import { PriceRangeProvider } from '../../context/prices/usePriceRanges';
import EditorInfo from '../general/EditorInfo';
import PriceListDropCardContent from './PriceListDropCardContent';

const PriceListDropCard = (props: { priceProduct: PriceProductDTO; id: number }) => {
  return (
    <div>
      <div key={props.priceProduct.id}>
        <PriceRangeProvider>
          <PriceListDropCardContent priceProduct={props.priceProduct} id={props.id} />
        </PriceRangeProvider>
        <div className='card-footer'>
          <div className='card-content-col'>
            <div className='card-content-col-inner border-none'>
              <DocumentTextIcon className='small-icon' />
              <p className='text-xs text-gray-500 ml-2 self-center'>29 sopimushintaa</p>
            </div>
            <div className='self-center float-right col-start-3  sm:px-2'>
              <EditorInfo
                modifiedAt={props.priceProduct.modifiedAt}
                modifiedByName={props.priceProduct.modifiedByName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PriceListDropCard;

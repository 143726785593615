import { useTranslation } from 'react-i18next';
const ErrorData = (props: { error: string }) => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t('general.dataStates.error')}</p>
      <p>{props.error}</p>
    </div>
  );
};
export default ErrorData;

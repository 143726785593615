import {
  PriceProductDTO,
  PriceProductRuleCategoryDTO,
  PriceProductRuleDTO,
  RuleApplication,
  RuleCurrency,
  RuleTarget,
  VatBase,
} from '@tiketti-team/tp-types-and-utils/types';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import countVat from '@tiketti-team/tp-types-and-utils/utils/vat/vat';
import classNamesStrBool from '@tiketti-team/tp-types-and-utils/utils/concatenate-css-classes/class-names-str-bool';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: {
    id: number;
    category: { id: number; name: { fi: string } };
    description: { fi: string };
    modifiedAt: string;
    modifiedByName: string;
    modifiedByUUID: string;
    name: { fi: string };
    ordinalInCategory: number | null;
    ruleCategories: {
      id: number;
      name: { fi: string };
      rules: {
        id: number;
        amount: number;
        application: RuleApplication | null;
        approved: boolean;
        approvedAt: string | null;
        approvedByName: string | null;
        createdAt: string;
        createdByName: string;
        createdByUUID: string;
        currency: RuleCurrency;
        deliveryTime: number | null;
        modifiedAt: string;
        modifiedByName: string;
        modifiedByUUID: string;
        priceRangeId: number | null;
        reviewComment: string | null;
        target: RuleTarget | null;
        validFrom: string | null;
        validTo: string | null;
        vatBase: VatBase;
      }[];
    }[];
  };
}
function convertCentsToPrice(priceInCents: number): string {
  const priceInEuros = priceInCents / 100;
  return priceInEuros.toFixed(2);
}

const PriceEditRulesVatSelect = (
  props: SaveEditedPriceProducts & { rules: PriceProductRuleDTO; ruleCategories: PriceProductRuleCategoryDTO }
) => {
  const handleChange = (vat: VatBase | undefined, ruleId: number | null) => {
    const price = props.rules.amount;
    let res = '';
    switch (vat) {
      case 'VAT_0':
        props.setSaveEditedRules((prevState) => {
          const updatedRuleCategories = prevState.ruleCategories.map((ruleCategory) => {
            const updatedRules = ruleCategory.rules.map((rule) => {
              if (rule.id === ruleId) {
                return { ...rule, vatBase: vat };
              }

              return rule;
            });
            return { ...ruleCategory, rules: updatedRules };
          });
          return { ...prevState, ruleCategories: updatedRuleCategories };
        });
        break;
      case 'VAT_10':
      case 'VAT_14':
      case 'VAT_24':
        res = isNaN(countVat(price, vat)) ? '0.00' : countVat(props.rules.amount, vat).toFixed(2);
        props.setSaveEditedRules((prevState) => {
          const updatedRuleCategories = prevState.ruleCategories.map((ruleCategory) => {
            const updatedRules = ruleCategory.rules.map((rule) => {
              if (rule.id === ruleId) {
                return { ...rule, vatBase: vat };
              }

              return rule;
            });
            return { ...ruleCategory, rules: updatedRules };
          });
          return { ...prevState, ruleCategories: updatedRuleCategories };
        });
        break;
      default:
        break;
    }

    return convertCentsToPrice(parseInt(res));

    return res;
  };

  return (
    <div className='col-span-2'>
      <div className='xs:w-full xxs:w-10/12 '>
        <div className='mt-3'>
          <button
            onClick={() => {
              handleChange('VAT_0', props.rules.id);
            }}
            className={classNamesStrBool(
              props.rules.vatBase !== 'VAT_0' && 'edit-rule-alv-button-rules',
              props.rules.vatBase === 'VAT_0' && 'edit-rule-alv-active-button-rules'
            )}
          >
            <p className='text-xs'>{t(`prices.priceRules.vatBase.${'VAT_0'}`)}</p>
          </button>
          <button
            onClick={() => {
              handleChange('VAT_10', props.rules.id);
            }}
            className={classNamesStrBool(
              props.rules.vatBase !== 'VAT_10' && 'edit-rule-alv-button-rules',
              props.rules.vatBase === 'VAT_10' && 'edit-rule-alv-active-button-rules'
            )}
          >
            <p className='text-xs'>{t(`prices.priceRules.vatBase.${'VAT_10'}`)}</p>
          </button>
          <button
            onClick={() => {
              handleChange('VAT_14', props.rules.id);
            }}
            className={classNamesStrBool(
              props.rules.vatBase !== 'VAT_14' && 'edit-rule-alv-button-rules',
              props.rules.vatBase === 'VAT_14' && 'edit-rule-alv-active-button-rules'
            )}
          >
            <p className='text-xs'>{t(`prices.priceRules.vatBase.${'VAT_14'}`)}</p>
          </button>
          <button
            onClick={() => {
              handleChange('VAT_24', props.rules.id);
            }}
            className={classNamesStrBool(
              props.rules.vatBase !== 'VAT_24' && 'edit-rule-alv-button-rules',
              props.rules.vatBase === 'VAT_24' && 'edit-rule-alv-active-button-rules'
            )}
          >
            <p className='text-xs'>{t(`prices.priceRules.vatBase.${'VAT_24'}`)}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceEditRulesVatSelect;

import { PriceProductDTO, PriceProductRuleCategoryDTO } from '@tiketti-team/tp-types-and-utils/types';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import PriceEditAddNewRange from './PriceEditAddNewRange';
import PriceEditAddNewRuleContainer from './PriceEditAddNewRuleContainer';
import PriceEditAddScheduleRule from './PriceEditAddScheduleRule';
interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
interface RuleCategoryList {
  ruleCategoryList: { id: number; content: JSX.Element }[];
  setRuleCategoryList: Dispatch<SetStateAction<{ id: number; content: JSX.Element }[]>>;
}
interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}
const PriceEditAddScheduleCategory = (
  props: SaveEditedPriceProducts & RuleCategoryList & NewRuleCategory & { newRuleCategoryId: number }
) => {
  const deleteRuleCategory = (ruleCategoryId: number) => {
    props.setRuleCategoryList((prevList) => {
      const updatedList = prevList.filter((ruleCategory) => ruleCategory.id !== ruleCategoryId);
      props.setNewRuleCategory((prevNewRuleCategory) =>
        prevNewRuleCategory.filter((category) => category.id !== ruleCategoryId)
      );
      return updatedList;
    });
  };

  const handleDelete = () => {
    deleteRuleCategory(props.newRuleCategoryId);
  };
  return (
    <div>
      <div key={props.newRuleCategoryId} className='edit-rule-card '>
        <div className='edit-rule-content mb-3 ml-2 p-1 mt-3'>
          <div className='edit-rule-container-rules lg:col-span-2'>
            <div className='flex flex-row'>
              <input
                className='edit-rule-price-header focus:outline-none '
                defaultValue={`${t('prices.priceDetail.priceCategoryLabel')}`}
              ></input>
            </div>
            <PriceEditAddNewRange />
          </div>
          <PriceEditAddScheduleRule
            saveEditedRules={props.saveEditedRules}
            setSaveEditedRules={props.setSaveEditedRules}
            ruleCategoryId={props.newRuleCategoryId}
            newRuleCategory={props.newRuleCategory}
            setNewRuleCategory={props.setNewRuleCategory}
          />
        </div>
        <PriceEditAddNewRuleContainer
          ruleListLength={0}
          saveEditedRules={props.saveEditedRules}
          setSaveEditedRules={props.setSaveEditedRules}
          ruleCategoryId={props.newRuleCategoryId}
          ruleCategories={{ id: props.newRuleCategoryId, name: { fi: '' }, rules: [] }}
          newRuleCategory={props.newRuleCategory}
          setNewRuleCategory={props.setNewRuleCategory}
        />
        <div className='edit-rule-footer'>
          <div className='flex flex-row content-center'>
            <button
              onClick={handleDelete}
              className='border-none text-red-600 p-2 mt-1 hover:bg-red-400 hover:rounded-full hover:text-white'
            >
              <p className='text-sm px-1'>{t('prices.priceDetail.removeRuleCategoryButtonLabel')}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceEditAddScheduleCategory;

import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import {
  RuleTarget,
  RuleApplication,
  PriceProductDTO,
  RuleCurrency,
  VatBase,
  PriceProductRuleCategoryDTO,
} from '@tiketti-team/tp-types-and-utils/types';
import classNamesStrBoolNum from '@tiketti-team/tp-types-and-utils/utils/concatenate-css-classes/class-names-str-bool-num';
import PriceEditAddNewRule from './PriceEditAddNewRule';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}
const PriceEditAddNewRuleContainer = (
  props: SaveEditedPriceProducts &
    NewRuleCategory & {
      ruleListLength: number;
      ruleCategoryId: number;
      ruleCategories: PriceProductRuleCategoryDTO;
    }
) => {
  const { t } = useTranslation();
  const [ruleInputList, setRuleInputList] = useState<{ id: number; content: JSX.Element }[]>([]);

  const addRule = (): void => {
    const allRuleIds = [
      ...props.saveEditedRules.ruleCategories.flatMap((ruleCategory) => ruleCategory.rules.map((rule) => rule.id)),
      ...props.newRuleCategory.flatMap((newCategory) => newCategory.rules.map((rule) => rule.id)),
    ];
    const ruleId = Math.max(...allRuleIds, 0) + 1;
    const newRule = {
      id: ruleId,
      amount: 0,
      application: 'FIXED_AMOUNT' as RuleApplication,
      approved: false,
      approvedAt: '',
      approvedByName: '',
      createdAt: '',
      createdByName: '',
      createdByUUID: '',
      currency: 'EUR' as RuleCurrency,
      deliveryTime: 0,
      modifiedAt: '',
      modifiedByName: '',
      modifiedByUUID: '',
      priceRangeId: 0,
      reviewComment: '',
      target: 'ORDER' as RuleTarget,
      validFrom: '',
      validTo: '',
      vatBase: 'VAT_0' as VatBase,
    };
    const updatedRuleCategories = props.saveEditedRules.ruleCategories.map((ruleCategory) =>
      ruleCategory.id === props.ruleCategoryId
        ? { ...ruleCategory, rules: [...ruleCategory.rules, newRule] }
        : ruleCategory
    );
    props.setSaveEditedRules({
      ...props.saveEditedRules,
      ruleCategories: updatedRuleCategories,
    });
    const categoryToUpdateIndex = props.newRuleCategory.findIndex((category) => category.id === props.ruleCategoryId);
    if (categoryToUpdateIndex !== -1) {
      const updatedNewRuleCategories = [...props.newRuleCategory];
      const categoryToUpdate = updatedNewRuleCategories[categoryToUpdateIndex];
      const updatedRules = [...categoryToUpdate.rules, newRule];
      const updatedCategory = { ...categoryToUpdate, rules: updatedRules };
      updatedNewRuleCategories[categoryToUpdateIndex] = updatedCategory;
      props.setNewRuleCategory(updatedNewRuleCategories);
    }
    const ruleContent = (
      <PriceEditAddNewRule
        key={ruleId}
        saveEditedRules={props.saveEditedRules}
        setSaveEditedRules={props.setSaveEditedRules}
        ruleCategories={{ id: props.ruleCategoryId, name: { fi: '' }, rules: [] }}
        rules={newRule}
        newRuleId={ruleId}
        ruleCategoryId={props.ruleCategoryId}
        newRuleCategory={props.newRuleCategory}
        setNewRuleCategory={props.setNewRuleCategory}
      />
    );
    setRuleInputList([...ruleInputList, { id: ruleId, content: ruleContent }]);
  };

  return (
    <div>
      <div>
        {ruleInputList.map((rule) => (
          <PriceEditAddNewRule
            key={rule.id}
            saveEditedRules={props.saveEditedRules}
            setSaveEditedRules={props.setSaveEditedRules}
            ruleCategories={{ id: props.ruleCategoryId, name: { fi: '' }, rules: [] }}
            rules={{
              id: 0,
              amount: 0,
              application: 'FIXED_AMOUNT',
              approved: false,
              approvedAt: '',
              approvedByName: '',
              createdAt: '',
              createdByName: '',
              createdByUUID: '',
              currency: 'EUR',
              deliveryTime: 0,
              modifiedAt: '',
              modifiedByName: '',
              modifiedByUUID: '',
              priceRangeId: 0,
              reviewComment: '',
              target: 'ORDER',
              validFrom: '',
              validTo: '',
              vatBase: 'VAT_0',
            }}
            newRuleId={rule.id}
            ruleCategoryId={props.ruleCategoryId}
            newRuleCategory={props.newRuleCategory}
            setNewRuleCategory={props.setNewRuleCategory}
          />
        ))}
      </div>
      <div className='edit-rule-price-container xxs:w-full xxs:mb-5 col-span-3'>
        <div className='flex flex-row content-center lg:justify-center ml-4 xl:mr-[215px] lg:mr-[50px] mb-8'>
          <div
            className={classNamesStrBoolNum(
              (props.ruleListLength + ruleInputList.length >= 7 && 'hidden') ||
                (ruleInputList.length >= 3 && props.ruleListLength === 0 && 'hidden') ||
                'visible'
            )}
          >
            <button onClick={addRule} className='edit-rules-add-rule-cate-button text-sm right-0'>
              <div className='flex flex-row'>
                <PlusCircleIcon className='w-5 h-5' />
                <p className='px-1 text-sm'>{t('prices.priceDetail.addRuleButtonLabel')}</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PriceEditAddNewRuleContainer;

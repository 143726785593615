import React from 'react';
import { priceRangeReducer, initialState, PriceRangeAction, PriceRangeState } from '../../reducers/priceRange';

type PriceRangeContextProps = {
  state: PriceRangeState;
  dispatch: React.Dispatch<PriceRangeAction>;
};
const PriceRangeContext = React.createContext<PriceRangeContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export function PriceRangeProvider(props: React.PropsWithChildren<Record<string, unknown>>) {
  const [state, dispatch] = React.useReducer(priceRangeReducer, initialState);
  return <PriceRangeContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function usePriceRanges() {
  const context = React.useContext(PriceRangeContext);
  if (!context) {
    throw new Error(`usePriceRange must be used within an PriceRangeProvider`);
  }
  return context;
}

import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';
import { useTranslation } from 'react-i18next';
import TopInfo from '../general/TopInfo';

const PriceEditTopInfo = (props: { priceProduct: PriceProductDTO }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='content-title'>
        <h1>
          {t('prices.priceDetail.editPriceLabel')} {t(`prices.category.name.fi.${props.priceProduct.category.name.fi}`)}
        </h1>
        <div className='py-3'>
          <TopInfo modifiedByName={props.priceProduct.modifiedByName} modifiedAt={props.priceProduct.modifiedAt} />
        </div>
      </div>
    </div>
  );
};
export default PriceEditTopInfo;

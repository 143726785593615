import React from 'react';
import { priceProductReducer, initialState, PriceProductAction, PriceProductState } from '../../reducers/priceProduct';

type PriceProductContextProps = {
  state: PriceProductState;
  dispatch: React.Dispatch<PriceProductAction>;
};
const PriceProductContext = React.createContext<PriceProductContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export function PriceProductProvider(props: React.PropsWithChildren<Record<string, unknown>>) {
  const [state, dispatch] = React.useReducer(priceProductReducer, initialState);
  return <PriceProductContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function usePriceProduct() {
  const context = React.useContext(PriceProductContext);
  if (!context) {
    throw new Error(`usePriceProduct must be used within an PriceProductProvider`);
  }
  return context;
}

import { PriceProductRuleCategoryDTO, PriceProductRuleDTO } from '@tiketti-team/tp-types-and-utils/types';
import { useTranslation } from 'react-i18next';
import { PriceRangeProvider } from '../../context/prices/usePriceRanges';
import './prices.css';
import PriceListDropRulePriceRange from './PriceListDropRulePriceRange';
import countVat from '@tiketti-team/tp-types-and-utils/utils/vat/vat';

const PriceListDropRuleInput = (props: { ruleCategories: PriceProductRuleCategoryDTO[] }) => {
  const { t } = useTranslation();

  const renderRuleVatBase = (ruledata: PriceProductRuleDTO) => {
    // TODO:selected to be value={ruledata.vatBase.toString()}
    const tempValue = ruledata.vatBase.toString();
    return <p className='input-label'>{t(`prices.priceRules.vatBase.${tempValue}`)}</p>;
  };

  return (
    <div>
      <div className='w-full'>
        {props.ruleCategories.map((rulesList) =>
          rulesList.rules.map((rule) => (
            <div key={rule.id} className='border-b border-gray-200 p-1'>
              <PriceRangeProvider>
                <PriceListDropRulePriceRange key={rule.id} rules={rule} />
              </PriceRangeProvider>
              <div className='lg:w-10/12 md:w-full'>
                <div className='sm:grid grid-cols-4 px-2 gap-2 smaller-screens-hidden-text'>
                  <p className='lg:col-span-2 text-sm mb-2'>{t('prices.priceDetail.priceRulesLabel')}</p>
                  <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
                  <p className='text-sm'>{`${t('prices.priceDetail.vatZeroPriceLabel')}`}</p>
                </div>
                <div className='sm:grid grid-cols-4 px-2 gap-3'>
                  <div className='smaller-screens-visible-text'>
                    <p className='text-sm'>{t('prices.priceDetail.priceRulesLabel')}</p>
                  </div>
                  <div className='lg:col-span-2'>
                    <div className='basic-text-input text-sm'>
                      <p>{`${t(`prices.priceRules.application.${rule.application}`)}  / ${t(
                        `prices.priceRules.target.${rule.target}`
                      )}`}</p>
                    </div>
                  </div>
                  <div className='smaller-screens-visible-text '>
                    <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
                  </div>
                  <div className='basic-text-input'>
                    <div className='flex flex-row relative'>
                      <p className='px-2'>{`${rule.amount}`}</p>
                      <p className='absolute right-0 text-gray-400'>
                        {t(`prices.priceRules.currency.${rule.currency.toString()}`)}
                      </p>
                    </div>
                  </div>
                  <div className='smaller-screens-visible-text '>
                    <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
                  </div>
                  <div className='basic-text-input'>
                    <div className='flex flex-row relative'>
                      <div className='px-2'>{<>{countVat(rule.amount, rule.vatBase)}</>}</div>

                      <p className='text-gray-400 absolute right-0'>
                        {t(`prices.priceRules.currency.${rule.currency.toString()}`)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='text-gray-400 p-3 ml-1'>
                  {rule.currency && rule.target && <>{renderRuleVatBase(rule)}</>}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PriceListDropRuleInput;

import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';
import { TagIcon } from '@heroicons/react/24/solid';
import EditorInfo from '../general/EditorInfo';
import { DocumentTextIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

const PriceListCard = (props: { priceProduct: PriceProductDTO }) => {
  const { t } = useTranslation();

  return (
    <div>
      {props.priceProduct.ruleCategories.map((item) =>
        item.rules.map((rule) => (
          <div key={rule.id}>
            <div className='content-center h-11 flex flex-row'>
              <div className='card-content-col w-full'>
                <div className='card-content-col-inner'>
                  <TagIcon className='small-icon' />
                  <p className='text-sm self-center ml-2'>
                    {rule.amount} {t(`prices.priceRules.currency.${rule.currency}`)}
                  </p>
                </div>
                <div className='col-span-2 self-center'>
                  <p className='text-sm ml-3'> {t(`prices.priceRules.target.${rule.target}`)}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      <div className='card-footer'>
        <div className='card-content-col'>
          <div className='card-content-col-inner border-none'>
            <DocumentTextIcon className='small-icon' />
            <p className='text-xs text-gray-500 ml-2 self-center'>29 sopimushintaa</p>
          </div>
          <div className='self-center float-right col-start-3  sm:px-2'>
            <EditorInfo modifiedAt={props.priceProduct.modifiedAt} modifiedByName={props.priceProduct.modifiedByName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceListCard;

import { PriceProductRuleDTO } from '@tiketti-team/tp-types-and-utils/types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getPriceRangeById } from '../../apis/PriceApi';
import usePriceRanges from '../../context/prices/usePriceRanges';
import ErrorData from '../general/states/ErrorData';
import Loading from '../general/states/Loading';

// --> TYPES-UTILS
function convertCentsToPrice(priceInCents: number): string {
  const priceInEuros = priceInCents / 100;
  return priceInEuros.toFixed(2);
}
const PriceEditCurrentRulesRange = (props: { rules: PriceProductRuleDTO }) => {
  const { t } = useTranslation();
  const {
    state: { priceranges, loading, error },
    dispatch,
  } = usePriceRanges();

  useEffect(() => {
    let ignore = false;
    async function fetchPriceRanges() {
      dispatch({ type: 'FETCH_PRICE_RANGE_BEGIN' });
      try {
        const payload = await getPriceRangeById(props.rules.priceRangeId);
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_RANGE_SUCCESS', payload: { priceranges: payload.data } });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_RANGE_ERROR', error: 'todo-error-message-from-server' });
        }
      }
    }
    fetchPriceRanges();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (priceranges === null) {
    return (
      <div className='edit-rule-range-container-rules'>
        <p className='ml-1 mb-2 text-sm'>{t('prices.priceRange.min')}</p>
        <p className='ml-1 text-sm '>{t('prices.priceRange.max')}</p>
        <div className='edit-rule-range-input-container-rules'>
          <div className='edit-rule-range-input-item-rules'>
            <div className='edit-rule-price-current-input-rules xxs:w-full relative'>
              <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
                <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
              </div>
              <p className='px-2'>0</p>
            </div>
          </div>
          <p className='flex justify-center mt-2 sm:ml-7 xxs:ml-5 lg:ml-5'>-</p>
          <div className='edit-rule-range-input-item-rules'>
            <div className='edit-rule-price-current-input-rules xxs:w-full relative'>
              <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
                <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
              </div>
              <p className='px-2'>0</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (error) {
    return <ErrorData error={error} />;
  }
  return (
    <div className='edit-rule-range-container-rules'>
      <p className='ml-1 mb-2 text-sm'>{t('prices.priceRange.min')}</p>
      <p className='ml-1 text-sm '>{t('prices.priceRange.max')}</p>
      <div className='edit-rule-range-input-container-rules'>
        <div className='edit-rule-range-input-item-rules'>
          <div className='edit-rule-price-current-input-rules xxs:w-full relative'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <p className='px-2'>{convertCentsToPrice(priceranges.minAmount)}</p>
          </div>
        </div>
        <p className='flex justify-center mt-2 sm:ml-7 xxs:ml-5 lg:ml-5'>-</p>
        <div className='edit-rule-range-input-item-rules'>
          <div className='edit-rule-price-current-input-rules xxs:w-full relative'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <p className='px-2'>{convertCentsToPrice(priceranges.maxAmount)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PriceEditCurrentRulesRange;

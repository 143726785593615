import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryList } from '../../apis/PriceApi';
import useCategories from '../../context/prices/usePriceListCategories';
import ErrorData from '../general/states/ErrorData';
import Loading from '../general/states/Loading';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
const PriceEditNameDescCard = (props: SaveEditedPriceProducts) => {
  const [hasUserInput, setHasUserInput] = useState(false);

  const { t } = useTranslation();
  const {
    state: { categorylists, loading, error },
    dispatch,
  } = useCategories();

  useEffect(() => {
    let ignore = false;
    async function fetchPriceList() {
      dispatch({ type: 'FETCH_PRICE_LIST_CATEGORY_BEGIN' });
      try {
        const payload = await getCategoryList();
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_LIST_CATEGORY_SUCCESS', payload: { categorylists: payload.data } });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_LIST_CATEGORY_ERROR', error: 'todo-error-message-from-server' });
        }
      }
    }
    fetchPriceList();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorData error={error} />;
  }

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    setHasUserInput(true);
    props.setSaveEditedRules((prevState) => ({
      ...prevState,
      description: {
        fi: value,
      },
    }));
  };

  const translatedValue = t(`prices.description.fi.${props.saveEditedRules.description.fi}`);
  const displayValue = hasUserInput ? props.saveEditedRules.description.fi : translatedValue;

  const handleCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    props.setSaveEditedRules((prevState) => ({
      ...prevState,
      category: {
        ...prevState.category,
        [name]: parseInt(value),
      },
    }));
  };
  const handleCategoryNameChange = () => {
    const inputId = parseInt(props.saveEditedRules.category.id.toString());
    const category = categorylists.find((c) => c.id === inputId);
    let categoryName = '';
    if (category?.name.fi) {
      categoryName = category?.name.fi;
      props.saveEditedRules.category.name.fi = categoryName;
    }
  };
  const handleSave = () => {
    props.setSaveEditedRules(props.saveEditedRules);
  };

  return (
    <div className='edit-rule-card lg:w-6/12'>
      <div className='edit-rule-content-desc xss:grid grid-cols-2 xxs:justify-center sm:p-2'>
        <div className='p-4 mt-2 col-span-2'>
          <p className='font-bold'>{t('prices.priceDetail.editPriceNameDescInfo.header')}</p>
          <p className='text-gray-600 text-sm py-2'>{t('prices.priceDetail.editPriceNameDescInfo.desc')}</p>
        </div>
        <div className='col-span-3 mt-5'>
          <div className='edit-rule-container-desc'>
            <div className='h-10/12'>
              <textarea
                name='fi'
                onChange={handleDescriptionChange}
                value={displayValue}
                className='edit-rule-textarea-desc'
              ></textarea>
            </div>
            <div className='py-3'>
              <p className='py-2'>{t('prices.priceDetail.editPriceNameDescInfo.selectHeader')}</p>
              <select
                className='edit-rule-select-desc text-sm'
                name='id'
                value={props.saveEditedRules.category.id.toString()}
                onChange={handleCategoryChange}
                onClick={handleCategoryNameChange}
              >
                {categorylists.map((category) => (
                  <option key={category.id} value={parseInt(category.id.toString())}>
                    {t(`prices.category.name.fi.${category.name.fi}`)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='edit-rule-footer bg-slate-50'>
        <div className='edit-rule-button-container '>
          <button className='edit-rule-save-button mr-3' onClick={handleSave}>
            <p className='text-sm px-1'>{t('prices.priceDetail.editPriceNameDescInfo.saveDescNameButtonLabel')}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceEditNameDescCard;

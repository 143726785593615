import { useEffect } from 'react';
import { getPriceList } from '../../apis/PriceApi';
import usePrices from '../../context/prices/usePrices';
import ErrorData from '../general/states/ErrorData';
import Loading from '../general/states/Loading';
import NoData from '../general/states/NoData';
import PriceListItem from './PriceListItem';

export default function PriceList() {
  const {
    state: { pricelists, loading, error },
    dispatch,
  } = usePrices();

  useEffect(() => {
    let ignore = false;
    async function fetchPriceList() {
      dispatch({ type: 'FETCH_PRICE_LIST_BEGIN' });
      try {
        const payload = await getPriceList();
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_LIST_SUCCESS', payload: { pricelists: payload.data } });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_LIST_ERROR', error: 'todo-error-message-from-server' });
        }
      }
    }
    fetchPriceList();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorData error={error} />;
  }

  return (
    <div className='py-4'>
      <ul role='list'>
        <hr className='list-item-outer-line'></hr>
        <div className='divide-y divide-gray-400'>
          {pricelists.length === 0 && <NoData />}
          {pricelists.length > 0 &&
            pricelists.map((pricelist) => (
              <div key={pricelist.id}>
                <PriceListItem key={pricelist.id} priceList={pricelist} />
              </div>
            ))}
        </div>
        <hr className='list-item-outer-line'></hr>
      </ul>
    </div>
  );
}

import {
  PriceProductDTO,
  PriceProductRuleCategoryDTO,
  PriceProductRuleDTO,
} from '@tiketti-team/tp-types-and-utils/types';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState, ChangeEvent } from 'react';
import { PriceProductRuleTargetsAndApplicationProvider } from '../../context/prices/usePriceProductRuleTargetsAndApplications';
import PriceEditAddNewRange from './PriceEditAddNewRange';
import { PriceEditRulesTargetApplicationSelect } from './PriceEditRulesTargetApplicationSelect';
import PriceEditRulesVatSelect from './PriceEditRulesVatSelect';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}

const PriceEditAddNewRule = (
  props: SaveEditedPriceProducts &
    NewRuleCategory & {
      ruleCategoryId: number;
      ruleCategories: PriceProductRuleCategoryDTO;
      newRuleId: number;
      rules: PriceProductRuleDTO;
    }
) => {
  // WIP
  const [ruleInput, setRuleInput] = useState({
    amount: 0,
    alv: '',
  });
  const [calculatedALV, setCalculatedALV] = useState({
    alv: '',
  });
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setRuleInput({ ...ruleInput, [name]: value });
    setCalculatedALV({ ...calculatedALV, [name]: value });
  };

  return (
    <div>
      <div className='edit-rule-content mb-3 ml-2 p-1 mt-3'>
        <div className='edit-rule-container-rules lg:col-span-2'>
          <div className='mt-11'>
            <PriceEditAddNewRange />
          </div>
        </div>
      </div>
      <div className='edit-rule-price-container xxs:w-full xxs:mb-5 col-span-3'>
        <p className='edit-rule-price-header'> {t('prices.priceDetail.priceCategoryRuleLabel')}</p>
        <div className='mr-3'>
          <div className='edit-rules-input-headers-rules smaller-screens-hidden-text'>
            <p className='lg:col-span-2 text-sm mb-2'>{t('prices.priceDetail.priceRulesLabel')}</p>
            <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
            <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
          </div>
          <div className='edit-rules-input-container-rules relative'>
            <PriceProductRuleTargetsAndApplicationProvider>
              <PriceEditRulesTargetApplicationSelect
                saveEditedRules={props.saveEditedRules}
                setSaveEditedRules={props.setSaveEditedRules}
                ruleCategories={{ id: props.ruleCategoryId, name: { fi: '' }, rules: [] }}
                rules={{
                  id: props.newRuleId,
                  amount: 0,
                  application: 'FIXED_AMOUNT',
                  approved: false,
                  approvedAt: '',
                  approvedByName: '',
                  createdAt: '',
                  createdByName: '',
                  createdByUUID: '',
                  currency: 'EUR',
                  deliveryTime: 0,
                  modifiedAt: '',
                  modifiedByName: '',
                  modifiedByUUID: '',
                  priceRangeId: 0,
                  reviewComment: '',
                  target: 'ORDER',
                  validFrom: '',
                  validTo: '',
                  vatBase: 'VAT_0',
                }}
                newRuleId={props.newRuleId}
                ruleCategoryId={props.ruleCategoryId}
                newRuleCategory={props.newRuleCategory}
                setNewRuleCategory={props.setNewRuleCategory}
              />
            </PriceProductRuleTargetsAndApplicationProvider>
            <div className='edit-rules-visible-small-screen'>
              <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
            </div>
            <div className='relative edit-rule-input-content-rules'>
              <div className='pointer-events-none absolute inset-y-2 right-0 mr-3 '>
                <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
              </div>
              <input
                name='amount'
                value={ruleInput.amount}
                onChange={onChange}
                className='edit-rules-inputs-rules w-full appearance-none'
              ></input>
            </div>
            <div className='edit-rules-visible-small-screen'>
              <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
            </div>
            <div className='relative edit-rule-input-content-rules'>
              <div className='pointer-events-none absolute inset-y-2 right-0 mr-3 '>
                <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
              </div>
              <input
                disabled
                name='alv'
                value={calculatedALV.alv}
                onChange={onChange}
                className='edit-rules-inputs-rules w-full'
              ></input>
            </div>
          </div>
          <PriceEditRulesVatSelect
            saveEditedRules={props.saveEditedRules}
            setSaveEditedRules={props.setSaveEditedRules}
            ruleCategories={{ id: 0, name: { fi: '' }, rules: [] }}
            rules={{
              id: 0,
              amount: 0,
              application: 'FIXED_AMOUNT',
              approved: false,
              approvedAt: '',
              approvedByName: '',
              createdAt: '',
              createdByName: '',
              createdByUUID: '',
              currency: 'EUR',
              deliveryTime: 0,
              modifiedAt: '',
              modifiedByName: '',
              modifiedByUUID: '',
              priceRangeId: 0,
              reviewComment: '',
              target: 'ORDER',
              validFrom: '',
              validTo: '',
              vatBase: 'VAT_0',
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PriceEditAddNewRule;

import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PriceListDropRuleInput from './PriceListDropRuleInput';

const PriceListDropCardContent = (props: { priceProduct: PriceProductDTO; id: number }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const openEditPage = () => {
    navigate('/priceedit', {
      state: {
        id: props.priceProduct.id,
      },
    });
  };

  return (
    <div className='border-x border-gray-200'>
      <div className='p-3 border-b border-gray-200'>
        <textarea
          rows={5}
          disabled
          className='text-area placeholder:p-2 placeholder:text-black'
          placeholder={`${t(`prices.category.name.fi.${props.priceProduct.category.name.fi}`)}.\n${t(
            `prices.description.fi.${props.priceProduct.description.fi}`
          )}`}
        ></textarea>
      </div>
      <div className='px-2 border-b border-gray-200 '>
        <PriceListDropRuleInput ruleCategories={props.priceProduct.ruleCategories} />
      </div>
      <Link to='/priceedit' state={{ id: props.priceProduct.id }}>
        <button
          className='price-edit-rule'
          onClick={() => {
            openEditPage;
          }}
        >
          {t('prices.priceDetail.moveToEditPageButton')}
        </button>
      </Link>
    </div>
  );
};
export default PriceListDropCardContent;

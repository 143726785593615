import { t } from 'i18next';

const PriceEditAddNewRange = () => {
  return (
    <div className='edit-rule-range-container-rules'>
      <p className='ml-1 mb-2 text-sm'>{t('prices.priceRange.min')}</p>
      <p className='ml-1 text-sm '>{t('prices.priceRange.max')}</p>
      <div className='edit-rule-range-input-container-rules'>
        <div className='edit-rule-range-input-item-rules relative'>
          <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
            <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
          </div>
          <input className='edit-rule-range-input-rules'></input>
        </div>
        <p className='flex justify-center mt-2 sm:ml-7 xxs:ml-5'>-</p>
        <div className='edit-rule-range-input-item-rules relative'>
          <div className='pointer-events-none absolute inset-y-2 right-0 mr-3'>
            <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
          </div>
          <input className='edit-rule-range-input-rules'></input>
        </div>
      </div>
    </div>
  );
};

export default PriceEditAddNewRange;

import { SVGProps } from 'react';

const DesktopNavigationItem = (props: {
  item: {
    name: string;
    href: string;
    icon: (
      props: SVGProps<SVGSVGElement> & {
        title?: string | undefined;
        titleId?: string | undefined;
      }
    ) => JSX.Element;
    current: boolean;
  };
}) => {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <a
      key={props.item.name}
      href={props.item.href}
      className={classNames(
        props.item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
      )}
    >
      <props.item.icon
        className={classNames(
          props.item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
          'mr-3 flex-shrink-0 h-6 w-6'
        )}
        aria-hidden='true'
      />
      {props.item.name}
    </a>
  );
};
export default DesktopNavigationItem;

import { PriceProductDTO, PriceProductRuleCategoryDTO, PriceRangeDTO } from '@tiketti-team/tp-types-and-utils/types';
import { useTranslation } from 'react-i18next';
import { PriceRangeProvider } from '../../context/prices/usePriceRanges';
import PriceEditScheduleCurrentRange from './PriceEditScheduleCurrentRange';
import PriceEditScheduleCurrentRules from './PriceEditScheduleCurrentRules';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import PriceEditAddNewRuleContainer from './PriceEditAddNewRuleContainer';

interface SaveEditedRange {
  setSaveEditedRange: Dispatch<SetStateAction<PriceRangeDTO>>;
  saveEditedRange: {
    id: number;
    maxAmount: number;
    minAmount: number;
  };
}

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}

interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}

interface ValidationError {
  setIsValidationError: Dispatch<SetStateAction<boolean>>;
  isValidationError: boolean;
}

const PriceEditScheduleCurrentCategory = (
  props: SaveEditedPriceProducts & SaveEditedRange & ValidationError & NewRuleCategory
) => {
  const { t } = useTranslation();
  const [translatedValues, setTranslatedValues] = useState<Record<number, string>>({});

  const handleRuleCategoryNameChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const { value } = event.currentTarget;
    const ruleCategoryId = parseInt(id);

    setTranslatedValues((prevTranslatedValues) => ({
      ...prevTranslatedValues,
      [ruleCategoryId]: value,
    }));

    props.setSaveEditedRules((prevSaveEditedRules) => {
      const updatedRuleCategories = prevSaveEditedRules.ruleCategories.map((ruleCategory) => {
        if (ruleCategory.id === ruleCategoryId) {
          return {
            ...ruleCategory,
            name: {
              fi: value,
            },
          };
        } else {
          return ruleCategory;
        }
      });
      return {
        ...prevSaveEditedRules,
        ruleCategories: updatedRuleCategories,
      };
    });
  };

  const getTranslatedValue = (ruleCategory: PriceProductRuleCategoryDTO) => {
    const ruleCategoryId = ruleCategory.id;
    if (Object.prototype.hasOwnProperty.call(translatedValues, ruleCategoryId)) {
      return translatedValues[ruleCategoryId];
    }
    return t(`prices.ruleCategory.name.fi.${ruleCategory.name.fi}`);
  };
  return (
    <div>
      {props.saveEditedRules.ruleCategories.map((ruleCategory) => (
        <div key={ruleCategory.id}>
          <div className='edit-rule-card'>
            <div className='edit-rule-content lg2:h-[175px] ml-2 mt-3'>
              <div className='edit-rule-container-rules lg:col-span-2'>
                <div className='flex flex-row mt-1'>
                  <input
                    name='fi'
                    value={getTranslatedValue(ruleCategory)}
                    onChange={(event) => handleRuleCategoryNameChange(event, ruleCategory.id.toString())}
                    className='p-1 mb-1 text-lg focus:outline-none'
                  />
                </div>
                <div>
                  {ruleCategory.rules
                    .filter((rule) => {
                      return rule.createdAt !== '';
                    })
                    .map((rule) => (
                      <div key={rule.id}>
                        <PriceRangeProvider>
                          <PriceEditScheduleCurrentRange
                            key={ruleCategory.id}
                            rules={rule}
                            saveEditedRange={props.saveEditedRange}
                            setSaveEditedRange={props.setSaveEditedRange}
                            isValidationError={props.isValidationError}
                            setIsValidationError={props.setIsValidationError}
                          />
                        </PriceRangeProvider>
                      </div>
                    ))}
                </div>
              </div>
              <div className='xxs:w-full xxs:mb-5 col-span-4'>
                <p className='edit-rule-price-header cursor-default'>
                  {t('prices.priceDetail.priceCategoryRuleLabel')}
                </p>
                <div>
                  {ruleCategory.rules
                    .filter((rule) => {
                      return rule.createdAt !== '';
                    })
                    .map((rule) => (
                      <div key={rule.id}>
                        <PriceEditScheduleCurrentRules
                          key={rule.id}
                          rules={rule}
                          saveEditedRules={props.saveEditedRules}
                          setSaveEditedRules={props.setSaveEditedRules}
                          ruleCategories={ruleCategory}
                          isValidationError={props.isValidationError}
                          setIsValidationError={props.setIsValidationError}
                          newRuleCategory={props.newRuleCategory}
                          setNewRuleCategory={props.setNewRuleCategory}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <PriceEditAddNewRuleContainer
              ruleListLength={ruleCategory.rules.length}
              saveEditedRules={props.saveEditedRules}
              setSaveEditedRules={props.setSaveEditedRules}
              ruleCategoryId={ruleCategory.id}
              ruleCategories={ruleCategory}
              newRuleCategory={props.newRuleCategory}
              setNewRuleCategory={props.setNewRuleCategory}
            />
            <div className='edit-rule-footer'>
              <div className='flex flex-row content-center'></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default PriceEditScheduleCurrentCategory;

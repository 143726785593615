import { PriceListWithPriceProductsDTO } from '@tiketti-team/tp-types-and-utils/types';

export type PriceListWithProductsAction =
  | { type: 'FETCH_PRICE_LIST_WITH_PRODUCTS_BEGIN' }
  | {
      type: 'FETCH_PRICE_LIST_WITH_PRODUCTS_SUCCESS';
      payload: { pricelistWithProducts: PriceListWithPriceProductsDTO };
    }
  | { type: 'FETCH_PRICE_LIST_WITH_PRODUCTS_ERROR'; error: string };

export interface PriceListWithProductsState {
  pricelistWithProducts: PriceListWithPriceProductsDTO | null;
  loading: boolean;
  error: string | null;
  initial: boolean;
}

export const initialState: PriceListWithProductsState = {
  pricelistWithProducts: null,
  loading: false,
  error: null,
  initial: true,
};

export function priceListWithProductsReducer(
  state: PriceListWithProductsState,
  action: PriceListWithProductsAction
): PriceListWithProductsState {
  switch (action.type) {
    case 'FETCH_PRICE_LIST_WITH_PRODUCTS_BEGIN':
      return {
        ...state,
        loading: true,
        error: null,
        initial: false,
      };
    case 'FETCH_PRICE_LIST_WITH_PRODUCTS_SUCCESS':
      return {
        ...state,
        loading: false,
        initial: false,
        pricelistWithProducts: action.payload.pricelistWithProducts,
      };
    case 'FETCH_PRICE_LIST_WITH_PRODUCTS_ERROR':
      return {
        ...state,
        loading: false,
        initial: false,
        error: action.error,
        pricelistWithProducts: null,
      };
    default:
      return state;
  }
}

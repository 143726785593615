import React from 'react';
import {
  priceCategoryListReducer,
  initialState,
  PriceCategoryListAction,
  PriceCategoryListState,
} from '../../reducers/priceCategoryList';

type PriceListContextProps = {
  state: PriceCategoryListState;
  dispatch: React.Dispatch<PriceCategoryListAction>;
};

const PriceCategoryListContext = React.createContext<PriceListContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export function PriceCategoryListProvider(props: React.PropsWithChildren<Record<string, unknown>>) {
  const [state, dispatch] = React.useReducer(priceCategoryListReducer, initialState);
  return <PriceCategoryListContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function useCategories() {
  const context = React.useContext(PriceCategoryListContext);
  if (!context) {
    throw new Error(`useCategories must be used within an PriceCategoryListProvider`);
  }
  return context;
}

import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PriceListCard from './PriceListCard';
import PriceListDropCard from './PriceListDropCard';

const PriceListItemDropDown = (props: { priceProducts: PriceProductDTO; id: number }) => {
  const [dropOpen, setDropOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className='card mb-4' key={props.priceProducts.id}>
      <div
        className='card-header'
        onClick={() => {
          setDropOpen((prev) => !prev);
        }}
      >
        {!dropOpen ? <ChevronRightIcon className='small-icon' /> : <ChevronDownIcon className='small-icon' />}
        <p className='card-header-text'>{`${t(`prices.priceProducts.name.fi.${props.priceProducts.name.fi}`)}`}</p>
      </div>
      <div key={props.priceProducts.id}>
        <div>{!dropOpen && <PriceListCard key={props.priceProducts.id} priceProduct={props.priceProducts} />}</div>
        <div>
          {dropOpen && (
            <PriceListDropCard key={props.priceProducts.id} priceProduct={props.priceProducts} id={props.id} />
          )}
        </div>
      </div>
    </div>
  );
};
export default PriceListItemDropDown;

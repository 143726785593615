import { useTranslation } from 'react-i18next';
import PriceList from '../../components/prices/PriceList';
import { PriceListProvider } from '../../context/prices/usePrices';
import PriceListTopInfo from '../../components/prices/PriceListTopInfo';

export default function PriceListPage() {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className='content-title'>{t('prices.priceLists.priceListTitle')}</h1>
      <PriceListProvider>
        <PriceListTopInfo />
        <div className='list-container'>
          <PriceList />
        </div>
      </PriceListProvider>
    </div>
  );
}

import { PriceProductRuleTargetAndApplication } from '../reducers/priceProductRuleTargetsAndApplications';
import API from './utils/APIUtils';
import {
  PriceListDTO,
  PriceRangeDTO,
  PriceProductInputDTO,
  PriceProductCategoryDTO,
  PriceListWithPriceProductsDTO,
  PriceProductDTO,
} from '@tiketti-team/tp-types-and-utils/types';

export function getPriceList() {
  return API.get<Array<PriceListDTO>>(`/price-lists`);
}
export function getPriceListWithPriceProducts(priceListId: number) {
  return API.get<PriceListWithPriceProductsDTO>(`/price-lists/${priceListId}`);
}
export function getPriceProducts(priceProductId: number) {
  return API.get<PriceProductDTO>(`/price-product/${priceProductId}`);
}
export function getPriceRange() {
  return API.get<Array<PriceRangeDTO>>(`/price-ranges`);
}

export function getPriceRangeById(priceRangeId: number | null) {
  return API.get<PriceRangeDTO>(`/price-ranges/${priceRangeId}`);
}

export function getTargetsAndApplications() {
  return API.get<Array<PriceProductRuleTargetAndApplication>>('/price-product-rules/targets-applications');
}
export function getCategoryList() {
  return API.get<Array<PriceProductCategoryDTO>>(`/price-products/categories`);
}

export function putPriceRange(priceRangeId: number, updatedPriceRange: PriceRangeDTO) {
  return API.put<PriceRangeDTO>(`/price-ranges/${priceRangeId}`, updatedPriceRange);
}

export function putPriceProductRuleInput(priceProductId: number, data: PriceProductInputDTO) {
  return API.put<PriceProductInputDTO>(`/price-products/${priceProductId}`, data);
}

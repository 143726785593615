import { PriceRangeDTO } from '@tiketti-team/tp-types-and-utils/types';

export type PriceRangeAction =
  | { type: 'FETCH_PRICE_RANGE_BEGIN' }
  | {
      type: 'FETCH_PRICE_RANGE_SUCCESS';
      payload: { priceranges: PriceRangeDTO };
    }
  | { type: 'FETCH_PRICE_RANGE_ERROR'; error: string };

export interface PriceRangeState {
  priceranges: PriceRangeDTO | null;
  loading: boolean;
  error: string | null;
  initial: boolean;
}

export const initialState: PriceRangeState = {
  priceranges: null,
  loading: false,
  error: null,
  initial: true,
};

export function priceRangeReducer(state: PriceRangeState, action: PriceRangeAction): PriceRangeState {
  switch (action.type) {
    case 'FETCH_PRICE_RANGE_BEGIN':
      return {
        ...state,
        loading: true,
        error: null,
        initial: false,
      };
    case 'FETCH_PRICE_RANGE_SUCCESS':
      return {
        ...state,
        loading: false,
        initial: false,
        priceranges: action.payload.priceranges,
      };
    case 'FETCH_PRICE_RANGE_ERROR':
      return {
        ...state,
        loading: false,
        initial: false,
        error: action.error,
        priceranges: null,
      };
    default:
      return state;
  }
}

// PUT

import {
  PriceProductRuleDTO,
  PriceProductDTO,
  PriceProductRuleCategoryDTO,
} from '@tiketti-team/tp-types-and-utils/types';

import { Dispatch, SetStateAction, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import PriceEditRulesVatSelect from './PriceEditRulesVatSelect';
import { PriceEditRulesTargetApplicationSelect } from './PriceEditRulesTargetApplicationSelect';
import { PriceProductRuleTargetsAndApplicationProvider } from '../../context/prices/usePriceProductRuleTargetsAndApplications';
import countVat from '@tiketti-team/tp-types-and-utils/utils/vat/vat';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}

// --> TYPES-UTILS
function convertCentsToPrice(priceInCents: number): string {
  const priceInEuros = priceInCents / 100;
  return priceInEuros.toFixed(2);
}
// TYPES-UTILS
function calculatePriceToCents(price: number): number {
  const formattedPrice = price.toString().replace(',', '.');
  const parsedPrice = parseFloat(formattedPrice);

  if (isNaN(parsedPrice)) {
    return 0;
  }
  const priceInCents = Math.floor(parsedPrice * 100);
  return priceInCents;
}
// TYPES-UTILS
interface ErrorMessages {
  invalidNumber: string;
  maxLesserThanMin?: string;
  minGreaterThanMax?: string;
  zeroValueError: string;
}
interface ValidationResult {
  updatedAmount?: number;
  error?: string;
}
// TYPES-UTILS
interface ValidationError {
  setIsValidationError: Dispatch<SetStateAction<boolean>>;
  isValidationError: boolean;
}
// TYPES-UTILS
function validateAmount(amount: string, errorMessages: ErrorMessages): ValidationResult {
  if (amount === '0' || amount === '0.00' || amount === '0,00' || amount === '') {
    const errorMessage = errorMessages.zeroValueError;
    return { error: errorMessage };
  }
  if (!/^\d+(?:[.,]\d{1,2})?$/.test(amount)) {
    return { error: errorMessages.invalidNumber };
  }
  const numericValue = calculatePriceToCents(parseFloat(amount.replace(',', '.')));
  if (isNaN(numericValue)) {
    return { error: errorMessages.invalidNumber };
  }
  return { updatedAmount: numericValue };
}

const PriceEditScheduleCurrentRules = (
  props: SaveEditedPriceProducts &
    NewRuleCategory &
    ValidationError & {
      rules: PriceProductRuleDTO;
      ruleCategories: PriceProductRuleCategoryDTO;
    }
) => {
  const { t } = useTranslation();

  const formErrorRef = useRef('');
  const [newAmount, setNewAmount] = useState(convertCentsToPrice(props.rules.amount));
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNewAmount(newValue);
    const errorMessages: ErrorMessages = {
      invalidNumber: t('validate.invalidnumber'),
      zeroValueError: t('validate.notEmpty'),
    };
    props.setSaveEditedRules((prevState) => {
      const parsedValue = parseFloat(newValue.replace(',', '.'));
      const updatedAmount = parsedValue;
      const validation = validateAmount(newValue, errorMessages);
      if (validation.error) {
        formErrorRef.current = validation.error;
        props.setIsValidationError(true);
      } else {
        formErrorRef.current = '';
        props.setIsValidationError(false);
      }
      const updatedRules = {
        ...prevState,
        ruleCategories: prevState.ruleCategories.map((category) => ({
          ...category,
          rules: category.rules.map((rule) =>
            rule.id === props.rules.id ? { ...rule, amount: calculatePriceToCents(updatedAmount) } : rule
          ),
        })),
      };
      return updatedRules;
    });
  };
  const vatValue = isNaN(countVat(props.rules.amount, props.rules.vatBase))
    ? '0.00'
    : countVat(props.rules.amount, props.rules.vatBase).toFixed(2);
  return (
    <div>
      <div className='mr-3' key={props.rules.id}>
        <div className='edit-rules-input-headers-rules smaller-screens-hidden-text'>
          <p className='lg:col-span-2 text-sm mb-2'>{t('prices.priceDetail.priceRulesLabel')}</p>
          <div>
            <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
            {formErrorRef.current && <p className='text-red-500 text-sm p-1 mb-1'>{formErrorRef.current}</p>}
          </div>

          <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
        </div>
        <div className='edit-rules-input-container-rules relative'>
          <PriceProductRuleTargetsAndApplicationProvider>
            <PriceEditRulesTargetApplicationSelect
              saveEditedRules={props.saveEditedRules}
              setSaveEditedRules={props.setSaveEditedRules}
              ruleCategories={props.ruleCategories}
              rules={props.rules}
              newRuleId={0}
              ruleCategoryId={0}
              newRuleCategory={props.newRuleCategory}
              setNewRuleCategory={props.setNewRuleCategory}
            />
          </PriceProductRuleTargetsAndApplicationProvider>
          <div className='edit-rules-visible-small-screen'>
            <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
            {formErrorRef.current && <p className='text-red-500 text-sm p-1'>{formErrorRef.current}</p>}
          </div>
          <div className='relative edit-rule-input-content-rules'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3 '>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input
              name='amount'
              type='text'
              value={newAmount}
              onChange={handleAmountChange}
              className='edit-rules-inputs-rules w-full appearance-none'
            ></input>
          </div>
          <div className='edit-rules-visible-small-screen'>
            <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
          </div>
          <div className='relative edit-rule-input-content-rules'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3 '>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input
              disabled
              value={convertCentsToPrice(parseInt(vatValue))}
              className='edit-rules-inputs-rules w-full'
            ></input>
          </div>
        </div>
        <PriceEditRulesVatSelect
          ruleCategories={{ id: props.ruleCategories.id, name: { fi: '' }, rules: [] }}
          rules={props.rules}
          saveEditedRules={props.saveEditedRules}
          setSaveEditedRules={props.setSaveEditedRules}
        />
      </div>
    </div>
  );
};

export default PriceEditScheduleCurrentRules;

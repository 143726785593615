import {
  RuleTarget,
  RuleApplication,
  PriceProductDTO,
  PriceProductRuleCategoryDTO,
  PriceProductRuleDTO,
} from '@tiketti-team/tp-types-and-utils/types';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { getTargetsAndApplications } from '../../apis/PriceApi';
import usePriceProductRuleTargetsAndApplication from '../../context/prices/usePriceProductRuleTargetsAndApplications';
import ErrorData from '../general/states/ErrorData';
import Loading from '../general/states/Loading';
import NoData from '../general/states/NoData';
import { useTranslation } from 'react-i18next';
interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}
export const PriceEditRulesTargetApplicationSelect = (
  props: SaveEditedPriceProducts &
    NewRuleCategory & {
      ruleCategories: PriceProductRuleCategoryDTO;
      rules: PriceProductRuleDTO;
      ruleCategoryId: number;
      newRuleId: number;
    }
) => {
  const { t } = useTranslation();
  const [targetsAndApplicationsList, setTargetsAndApplicationsList] = useState<
    { target: RuleTarget; ruleApplication: RuleApplication }[]
  >([]);
  const [duplicateErrorIds, setDuplicateErrorIds] = useState<number[]>([]);
  const checkForDuplicateRules = (): void => {
    const allRuleCategories: PriceProductRuleCategoryDTO[] = props.saveEditedRules.ruleCategories.concat(
      props.newRuleCategory
    );

    const duplicateErrorIds: number[] = [];
    for (const ruleCategory of allRuleCategories) {
      const targetApplicationMap: Map<string, number[]> = new Map();
      const ruleCategoryErrorIds: number[] = [];
      for (const rule of ruleCategory.rules) {
        const targetApplicationKey = `${rule.target}-${rule.application}`;
        if (!targetApplicationMap.has(targetApplicationKey)) {
          targetApplicationMap.set(targetApplicationKey, []);
        }
        const ruleIds: number[] | undefined = targetApplicationMap.get(targetApplicationKey);
        if (ruleIds && ruleIds.length > 0) {
          for (const ruleId of ruleIds) {
            if (!ruleCategoryErrorIds.includes(ruleId)) {
              ruleCategoryErrorIds.push(ruleId);
            }
            if (!ruleCategoryErrorIds.includes(rule.id)) {
              ruleCategoryErrorIds.push(rule.id);
            }
          }
        }
        ruleIds?.push(rule.id);
      }
      if (ruleCategoryErrorIds.length > 0) {
        duplicateErrorIds.push(...ruleCategoryErrorIds);
      }
    }
    setDuplicateErrorIds(duplicateErrorIds);
  };

  useEffect(() => {
    checkForDuplicateRules();
  }, [props.saveEditedRules, props.ruleCategoryId, props.newRuleCategory]);

  const {
    state: { priceProductRuleTargetsAndApplications, loading, error },
    dispatch,
  } = usePriceProductRuleTargetsAndApplication();

  useEffect(() => {
    let ignore = false;
    async function fetchTargetsAndApplications() {
      dispatch({ type: 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_BEGIN' });
      try {
        const payload = await getTargetsAndApplications();
        const transformData = payload.data.map((list) => ({
          target: list.target,
          ruleApplication: list.ruleApplication,
        }));
        if (payload.data) {
          setTargetsAndApplicationsList(transformData);
        }
        if (!ignore) {
          dispatch({
            type: 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_SUCCESS',
            payload: { priceProductRuleTargetsAndApplications: payload.data },
          });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({
            type: 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_ERROR',
            error: 'todo-error-message-from-server',
          });
        }
      }
    }
    fetchTargetsAndApplications();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (priceProductRuleTargetsAndApplications === null) {
    return <NoData />;
  }
  if (error) {
    return <ErrorData error={error} />;
  }
  const handleTargetAndApplicationChange = (id: number, newApplication: RuleApplication, newTarget: RuleTarget) => {
    props.setSaveEditedRules((prevState) => {
      const updatedRuleCategories = prevState.ruleCategories.map((ruleCategory) => {
        const updatedRules = ruleCategory.rules.map((rule) => {
          if (rule.id === id) {
            return { ...rule, application: newApplication, target: newTarget };
          }
          return rule;
        });
        return { ...ruleCategory, rules: updatedRules };
      });
      return { ...prevState, ruleCategories: updatedRuleCategories };
    });

    const updatedNewRuleCategory = props.newRuleCategory.map((ruleCategory) => {
      const updatedRules = ruleCategory.rules.map((rule) => {
        if (rule.id === id) {
          return { ...rule, application: newApplication, target: newTarget };
        }
        return rule;
      });
      return { ...ruleCategory, rules: updatedRules };
    });

    props.setNewRuleCategory(updatedNewRuleCategory);
  };

  const ruleToFind = [...props.saveEditedRules.ruleCategories, ...props.newRuleCategory]
    .flatMap((category) => category.rules)
    .find((rule) => rule.id === (props.newRuleId || props.rules.id));

  const defaultApplication = ruleToFind?.application || props.rules.application;
  const defaultTarget = ruleToFind?.target || props.rules.target;
  return (
    <div className='lg:col-span-2'>
      <div key={props.ruleCategoryId}></div>
      {duplicateErrorIds.includes(props.rules.id || props.newRuleId) && (
        <p className='text-red-500 p-1 ml-1 text-sm'>{t(`prices.priceRuleInput.validate.ruleDublicates`)}</p>
      )}
      <div className='edit-rules-visible-small-screen'>
        <p className='text-sm px-1'>{t('prices.priceDetail.priceRulesLabel')}</p>
      </div>
      <select
        value={`${defaultApplication} / ${defaultTarget}`}
        className='edit-rules-inputs-rules lg:w-full sm:w-4/5 xxs:w-full relative'
        onChange={(event) => {
          const [newApplication, newTarget] = event.target.value.split(' / ');
          handleTargetAndApplicationChange(
            props.rules.id || props.newRuleId,
            newApplication as RuleApplication,
            newTarget as RuleTarget
          );
        }}
      >
        <option value={`${props.rules.application} / ${props.rules.target}`}>
          {`${t(`prices.priceRules.application.${props.rules.application}`)} / ${t(
            `prices.priceRules.target.${props.rules.target}`
          )}`}
        </option>
        {targetsAndApplicationsList
          .filter(
            (targetsAndApplications) =>
              `${targetsAndApplications.ruleApplication} / ${targetsAndApplications.target}` !==
              `${props.rules.application} / ${props.rules.target}`
          )
          .map((targetsAndApplications) => (
            <option
              key={`${targetsAndApplications.ruleApplication} / ${targetsAndApplications.target}`}
              value={`${targetsAndApplications.ruleApplication} / ${targetsAndApplications.target}`}
            >
              {`${t(`prices.priceRules.application.${targetsAndApplications.ruleApplication}`)} / ${t(
                `prices.priceRules.target.${targetsAndApplications.target}`
              )}`}
            </option>
          ))}
      </select>
    </div>
  );
};

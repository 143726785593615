import './App.css';
import { Suspense } from 'react';
import '@tiketti-team/tp-design-systems-ui/dist/index'; // css
import './index.css';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import PriceListPage from './pages/prices/PriceListPage';
import { NotFoundPage } from './pages/NotFoundPage';
import ExampleNavigation from './components/navigation/Sidebar';
import PriceEditPage from './pages/prices/PriceEditPage';
import { PriceListProvider } from './context/prices/usePrices';

function App() {
  return (
    <Suspense fallback='loading...'>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<PriceListPage />} />
            <Route path='/pricelists' element={<PriceListPage />} />
            <Route
              path={`/priceedit`}
              element={
                <PriceListProvider>
                  <PriceEditPage />
                </PriceListProvider>
              }
            />
            <Route path='/404' element={<NotFoundPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}
const Layout = () => {
  return (
    <>
      {ExampleNavigation()}
      <div className='flex flex-1 flex-col md:pl-40'>
        <main className='flex-1'>
          <div className='py-6'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 md:px-6 lg:px-6 xl:px-6 2xl:px-1'>
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default App;

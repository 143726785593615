import axios from 'axios';

// TODO:
// ... JWT or other auth config
axios.defaults.baseURL = process.env.REACT_APP_PRICE_API_BASE_URL;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
      case 404:
      case 403:
        // TODO: general routes and actions
        // fe. clean everything and throw user out to be sure
        break;
    }
    return Promise.reject(error.response);
  }
);

export default axios;

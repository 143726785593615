import { RuleTarget, RuleApplication } from '@tiketti-team/tp-types-and-utils/types';

export type PriceProductRuleTargetAndApplication = {
  target: RuleTarget;
  ruleApplication: RuleApplication;
};
export type PriceProductRuleTargetsAndApplicationAction =
  | { type: 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_BEGIN' }
  | {
      type: 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_SUCCESS';
      payload: { priceProductRuleTargetsAndApplications: Array<PriceProductRuleTargetAndApplication> };
    }
  | { type: 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_ERROR'; error: string };

export interface PriceProductRuleTargetsAndApplicationState {
  priceProductRuleTargetsAndApplications: Array<PriceProductRuleTargetAndApplication> | null;

  loading: boolean;
  error: string | null;
  initial: boolean;
}

export const initialState: PriceProductRuleTargetsAndApplicationState = {
  priceProductRuleTargetsAndApplications: null,
  loading: false,
  error: null,
  initial: true,
};

export function priceProductRuleTargetsAndApplicationReducer(
  state: PriceProductRuleTargetsAndApplicationState,
  action: PriceProductRuleTargetsAndApplicationAction
): PriceProductRuleTargetsAndApplicationState {
  switch (action.type) {
    case 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_BEGIN':
      return {
        ...state,
        loading: true,
        error: null,
        initial: false,
      };
    case 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        initial: false,
        priceProductRuleTargetsAndApplications: action.payload.priceProductRuleTargetsAndApplications,
      };
    case 'FETCH_PRICE_PRODUCT_RULE_TARGETS_APPLICATIONS_ERROR':
      return {
        ...state,
        loading: false,
        initial: false,
        error: action.error,
        priceProductRuleTargetsAndApplications: null,
      };
    default:
      return state;
  }
}

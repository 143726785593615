import React from 'react';
import { priceListReducer, initialState, PriceListAction, PriceListState } from '../../reducers/priceList';

type PriceListContextProps = {
  state: PriceListState;
  dispatch: React.Dispatch<PriceListAction>;
};

const PriceListContext = React.createContext<PriceListContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export function PriceListProvider(props: React.PropsWithChildren<Record<string, unknown>>) {
  const [state, dispatch] = React.useReducer(priceListReducer, initialState);
  return <PriceListContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function usePrices() {
  const context = React.useContext(PriceListContext);
  if (!context) {
    throw new Error(`usePrices must be used within an PriceListProvider`);
  }
  return context;
}

import { PriceProductDTO } from '@tiketti-team/tp-types-and-utils/types';

export type PriceProductAction =
  | { type: 'FETCH_PRICE_PRODUCT_BEGIN' }
  | {
      type: 'FETCH_PRICE_PRODUCT_SUCCESS';
      payload: { priceProduct: PriceProductDTO };
    }
  | { type: 'FETCH_PRICE_PRODUCT_ERROR'; error: string };

export interface PriceProductState {
  priceProduct: PriceProductDTO | null;
  loading: boolean;
  error: string | null;
  initial: boolean;
}

export const initialState: PriceProductState = {
  priceProduct: null,
  loading: false,
  error: null,
  initial: true,
};

export function priceProductReducer(state: PriceProductState, action: PriceProductAction): PriceProductState {
  switch (action.type) {
    case 'FETCH_PRICE_PRODUCT_BEGIN':
      return {
        ...state,
        loading: true,
        error: null,
        initial: false,
      };
    case 'FETCH_PRICE_PRODUCT_SUCCESS':
      return {
        ...state,
        loading: false,
        initial: false,
        priceProduct: action.payload.priceProduct,
      };
    case 'FETCH_PRICE_PRODUCT_ERROR':
      return {
        ...state,
        loading: false,
        initial: false,
        error: action.error,
        priceProduct: null,
      };
    default:
      return state;
  }
}

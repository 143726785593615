import { PriceListDTO } from '@tiketti-team/tp-types-and-utils/types';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import PriceListItemCard from './PriceListItemCard';
import { PriceListWithProductsProvider } from '../../context/prices/usePriceListWithProducts';

const PriceListItem = (props: { priceList: PriceListDTO }) => {
  const [dropOpen, setDropOpen] = useState(false);

  return (
    <div>
      <div className='list-item' onClick={() => setDropOpen((prev) => !prev)}>
        {!dropOpen ? <ChevronRightIcon className='small-icon' /> : <ChevronDownIcon className='small-icon' />}
        <p className='ml-3' key={props.priceList.id}>
          {props.priceList.name.fi}
        </p>
      </div>
      {dropOpen && (
        <div className='list-item-inner line-b'>
          <PriceListWithProductsProvider>
            <PriceListItemCard priceList={props.priceList} />
          </PriceListWithProductsProvider>
        </div>
      )}
    </div>
  );
};
export default PriceListItem;

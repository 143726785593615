import { PriceProductDTO, PriceRangeDTO } from '@tiketti-team/tp-types-and-utils/types';
import { format } from 'date-fns';
import React, { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from 'react';
import PriceEditSaveChangesDatepicker from './PriceEditSaveChangesDatepicker';
import { putPriceRange } from '../../apis/PriceApi';
import { useTranslation } from 'react-i18next';

interface ValidationError {
  setIsValidationError: Dispatch<SetStateAction<boolean>>;
  isValidationError: boolean;
}

interface SaveEditedRange {
  setSaveEditedRange: Dispatch<SetStateAction<PriceRangeDTO>>;
  saveEditedRange: {
    id: number;
    maxAmount: number;
    minAmount: number;
  };
}

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}

const PriceEditSaveChanges = (props: SaveEditedPriceProducts & SaveEditedRange & ValidationError) => {
  const { t } = useTranslation();
  const updatePriceRange = async (priceRangeId: number, priceRangeData: PriceRangeDTO) => {
    const response = await putPriceRange(priceRangeId, priceRangeData);
    const updatedPriceRange: PriceRangeDTO = response.data;
    return updatedPriceRange;
  };
  const [modifiedAtInput, setModifiedAtInput] = useState({
    date: format(new Date(), 'dd.MM.yyyy').toLocaleString(),
    hour: format(new Date(), 'HH'),
    minute: format(new Date(), 'mm'),
    second: format(new Date(), 'ss'),
  });
  const inputToFocus = useRef<HTMLInputElement>(null);
  const inputSecondFocus = useRef<HTMLInputElement>(null);
  const handleSave = async () => {
    const { date, hour, minute, second } = modifiedAtInput;
    const [day, month, year] = date.split('.');
    const modifiedDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second)
    );
    props.setSaveEditedRules((prevState) => ({
      ...prevState,
      modifiedAt: modifiedDate.toISOString(),
    }));
    props.setSaveEditedRange((prevSaveEditedRange) => ({
      ...prevSaveEditedRange,
      ...props.saveEditedRange,
    }));
    await updatePriceRange(props.saveEditedRange.id, props.saveEditedRange);
  };
  const moveToNextInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { key } = e as React.KeyboardEvent<HTMLInputElement>;
    const { target } = e as React.ChangeEvent<HTMLInputElement>;
    if (target.value.length >= 2 && key !== 'Backspace') {
      if (inputToFocus.current != null) {
        inputToFocus.current.focus();
      }
    }
  };
  const handleChangeFirstDigits = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setModifiedAtInput({ ...modifiedAtInput, [name]: value });
    if (e.target.value.length >= 2) {
      if (inputToFocus.current != null) {
        inputToFocus.current.focus();
      }
    }
  };
  const handleChangeSecondDigits = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setModifiedAtInput({ ...modifiedAtInput, [name]: value });
    if (e.target.value.length === 0) {
      if (inputSecondFocus.current != null) {
        inputSecondFocus.current.focus();
      }
    }
  };
  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setModifiedAtInput({ ...modifiedAtInput, [name]: value });
  };

  return (
    <div className='edit-rule-card lg:w-full'>
      <div className='edit-rule-content '>
        <div className='p-4 mt-2 col-span-2 ml-4'>
          <p className='font-bold'>{t('prices.priceDetail.editPriceDateTimeInfo.saveRules')}</p>
          <p className='text-gray-600'>{t('prices.priceDetail.editPriceDateTimeInfo.approveChanges')}</p>
        </div>
        <div className='col-span-3 mt-3 ml-2'>
          <div className=' flex flex-row'>
            <div className=' mr-2'>
              <div className='p-3'>
                <p className='font-bold mb-3'>{t('prices.priceDetail.editPriceDateTimeInfo.newRuleTakeEffect')}</p>
                <div className='grid grid-cols-2 gap-2'>
                  <p className='ml-1 mb-1 text-sm'>{t('prices.priceDetail.editPriceDateTimeInfo.date')}</p>
                  <p className='ml-1 text-sm'>{t('prices.priceDetail.editPriceDateTimeInfo.time')}</p>
                  <div className='relative'>
                    <input
                      name='date'
                      className='edit-rule-date-input-save lg:placeholder:10/12 mr-2'
                      value={modifiedAtInput.date}
                      onChange={handleDateChange}
                    ></input>
                    <PriceEditSaveChangesDatepicker
                      setModifiedAtInput={setModifiedAtInput}
                      modifiedAtInput={modifiedAtInput.date}
                    />
                  </div>
                  <div className='relative'>
                    <div className='pointer-events-none absolute inset-y-[3.5px] inset-x-[19px]'>
                      <span className='text-gray-800 sm:text-sm font-semibold px-2 ml-0.5'>:</span>
                    </div>
                    <div className='edit-rule-input-save'>
                      <input
                        name='hour'
                        ref={inputSecondFocus}
                        value={modifiedAtInput.hour}
                        onChange={handleChangeFirstDigits}
                        maxLength={2}
                        onKeyDown={moveToNextInputKeyPress}
                        className='edit-rule-time-input-save'
                      ></input>
                      <input
                        name='minute'
                        ref={inputToFocus}
                        value={modifiedAtInput.minute}
                        onChange={handleChangeSecondDigits}
                        onKeyDown={moveToNextInputKeyPress}
                        maxLength={2}
                        className='edit-rule-time-input-save w-8'
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='edit-rule-footer bg-slate-50'>
        <div className='grid grid-cols-3 w-full '>
          <div className=' edit-rule-button-container justify-start w-full'>
            <button className='edit-rules-add-rule-cate-button xs:h-10 sm:mt-1 xs:mt-1 xxs:mt-2 lg:ml-2'>
              <p className='sm:text-sm xs:px-1 xxs:text-xs'>
                {t('prices.priceDetail.editPriceDateTimeInfo.cancelAllChanges')}
              </p>
            </button>
          </div>
          <div className='edit-rule-button-container justify-end xxs:col-span-2'>
            <button
              className={`edit-rule-save-button ${props.isValidationError ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={handleSave}
            >
              <p className='xs:text-sm xxs:text-xs flex justify-center'>Tallenna ja hyväksytä</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PriceEditSaveChanges;

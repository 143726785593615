import { PriceProductDTO, PriceProductRuleCategoryDTO } from '@tiketti-team/tp-types-and-utils/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import PriceEditAddScheduleCategory from './PriceEditAddScheduleCategory';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}
const PriceEditAddScheduleCategoryContainer = (props: SaveEditedPriceProducts & NewRuleCategory) => {
  const { t } = useTranslation();
  const [ruleCategoryList, setRuleCategoryList] = useState<{ id: number; content: JSX.Element }[]>([]);
  const addCategory = () => {
    const existingRuleCategoryIds = props.saveEditedRules.ruleCategories.map((ruleCategory) => ruleCategory.id);
    const existingNewRuleCategoryIds = props.newRuleCategory.map((ruleCategory) => ruleCategory.id);

    const maxExistingRuleCategoryId = Math.max(...existingRuleCategoryIds, 0);
    const maxExistingNewRuleCategoryId = Math.max(...existingNewRuleCategoryIds, 0);

    const ruleCategoryId = Math.max(maxExistingRuleCategoryId, maxExistingNewRuleCategoryId) + 1;

    const ruleCategoryContent = (
      <PriceEditAddScheduleCategory
        key={ruleCategoryId}
        newRuleCategoryId={ruleCategoryId}
        saveEditedRules={props.saveEditedRules}
        setSaveEditedRules={props.setSaveEditedRules}
        ruleCategoryList={ruleCategoryList}
        setRuleCategoryList={setRuleCategoryList}
        newRuleCategory={props.newRuleCategory}
        setNewRuleCategory={props.setNewRuleCategory}
      />
    );

    const newRuleCategory = {
      id: ruleCategoryId,
      name: { fi: '' },
      rules: [],
    };

    setRuleCategoryList([...ruleCategoryList, { id: ruleCategoryId, content: ruleCategoryContent }]);
    props.setNewRuleCategory((prevCategories) => [...prevCategories, newRuleCategory]);
  };

  return (
    <div>
      <div className='w-full'>
        {ruleCategoryList.map((ruleCategory) => (
          <PriceEditAddScheduleCategory
            key={ruleCategory.id}
            newRuleCategoryId={ruleCategory.id}
            saveEditedRules={props.saveEditedRules}
            setSaveEditedRules={props.setSaveEditedRules}
            ruleCategoryList={ruleCategoryList}
            setRuleCategoryList={setRuleCategoryList}
            newRuleCategory={props.newRuleCategory}
            setNewRuleCategory={props.setNewRuleCategory}
          />
        ))}
      </div>
      <button onClick={addCategory} className='edit-rules-add-rule-cate-button p-2 mb-5'>
        <div className='flex flex-row'>
          <PlusCircleIcon className='w-5 h-5' />
          <p className='px-1 text-sm'>{t('prices.priceDetail.editPriceRules.addCategoryButtonLabel')}</p>
        </div>
      </button>
    </div>
  );
};
export default PriceEditAddScheduleCategoryContainer;

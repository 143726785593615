import './editorinfo.css';
import { PencilIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const EditorInfo = (props: { modifiedByName: string; modifiedAt: string }) => {
  const { t } = useTranslation();
  return (
    <div className='editor-info flex items-center'>
      <PencilIcon className='h-3 w-3 mr-1' />
      <p className='xs'>
        {format(new Date(props.modifiedAt), 'dd.MM.yyy')} {t('general.time.abbreviationName')}{' '}
        {format(new Date(props.modifiedAt), 'HH:mm')} | {props.modifiedByName}
      </p>
    </div>
  );
};
export default EditorInfo;

import { PriceListDTO } from '@tiketti-team/tp-types-and-utils/types';

export type PriceListAction =
  | { type: 'FETCH_PRICE_LIST_BEGIN' }
  | {
      type: 'FETCH_PRICE_LIST_SUCCESS';
      payload: { pricelists: Array<PriceListDTO> };
    }
  | { type: 'FETCH_PRICE_LIST_ERROR'; error: string };

export interface PriceListState {
  pricelists: Array<PriceListDTO>;
  loading: boolean;
  error: string | null;
  initial: boolean;
}

export const initialState: PriceListState = {
  pricelists: [],
  loading: false,
  error: null,
  initial: true,
};

export function priceListReducer(state: PriceListState, action: PriceListAction): PriceListState {
  switch (action.type) {
    case 'FETCH_PRICE_LIST_BEGIN':
      return {
        ...state,
        loading: true,
        error: null,
        initial: false,
      };
    case 'FETCH_PRICE_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        initial: false,
        pricelists: action.payload.pricelists,
      };
    case 'FETCH_PRICE_LIST_ERROR':
      return {
        ...state,
        loading: false,
        initial: false,
        error: action.error,
        pricelists: [],
      };
    default:
      return state;
  }
}

import { PriceProductDTO, PriceProductRuleCategoryDTO } from '@tiketti-team/tp-types-and-utils/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PriceEditAddScheduleCategoryContainer from './PriceEditAddScheduleCategoryContainer';
import PriceEditCurrentRules from './PriceEditCurrentRules';
import PriceEditScheduleCurrentCategory from './PriceEditScheduleCurrentCategory';
import PriceEditSaveChanges from './PriceEditSaveChanges';
import classNamesStrBool from '@tiketti-team/tp-types-and-utils/utils/concatenate-css-classes/class-names-str-bool';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}

const PriceEditRuleContainer = (props: SaveEditedPriceProducts & { priceProduct: PriceProductDTO }) => {
  const [isActive, setIsActive] = useState(false);
  const [saveEditedRange, setSaveEditedRange] = useState({
    id: 0,
    maxAmount: 0,
    minAmount: 0,
  });
  const [isValidationError, setIsValidationError] = useState(false);
  const { t } = useTranslation();
  const [newRuleCategory, setNewRuleCategory] = useState<PriceProductRuleCategoryDTO[]>([]);

  return (
    <div>
      <div className='lg:w-10/12 mt-8'>
        <div className='edit-rule-buttons-container-rules'>
          <div className='flex flex-row '>
            <div onClick={() => setIsActive((prev) => !prev)}>
              <button
                onClick={() => setIsActive(true)}
                className={classNamesStrBool(
                  'edit-rule-nav-buttons-rules xs:mr-6 xxs:mr-2',
                  !isActive && 'edit-rule-nav-buttons-active-rules'
                )}
              >
                <p className='xxs:text-xs xxs2:text-xs xs2:text-base'>
                  {t('prices.priceDetail.editPriceRules.scheduledRuleLabel')}
                </p>
              </button>
              <button
                onClick={() => setIsActive(false)}
                className={classNamesStrBool(
                  'edit-rule-nav-buttons-rules',
                  isActive && 'edit-rule-nav-buttons-active-rules'
                )}
              >
                <p className=' xxs2:text-xs xxs:text-xs xs2:text-base'>
                  {t('prices.priceDetail.editPriceRules.currentRuleLabel')}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='py-6 '>
        {!isActive && (
          <>
            <PriceEditScheduleCurrentCategory
              saveEditedRules={props.saveEditedRules}
              setSaveEditedRules={props.setSaveEditedRules}
              isValidationError={isValidationError}
              setIsValidationError={setIsValidationError}
              saveEditedRange={saveEditedRange}
              setSaveEditedRange={setSaveEditedRange}
              newRuleCategory={newRuleCategory}
              setNewRuleCategory={setNewRuleCategory}
            />
            <PriceEditAddScheduleCategoryContainer
              saveEditedRules={props.saveEditedRules}
              setSaveEditedRules={props.setSaveEditedRules}
              newRuleCategory={newRuleCategory}
              setNewRuleCategory={setNewRuleCategory}
            />
            <PriceEditSaveChanges
              saveEditedRules={props.saveEditedRules}
              setSaveEditedRules={props.setSaveEditedRules}
              saveEditedRange={saveEditedRange}
              setSaveEditedRange={setSaveEditedRange}
              isValidationError={isValidationError}
              setIsValidationError={setIsValidationError}
            />
          </>
        )}
        {isActive && (
          <>
            <PriceEditCurrentRules
              saveEditedRules={props.saveEditedRules}
              setSaveEditedRules={props.setSaveEditedRules}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default PriceEditRuleContainer;

import { t } from 'i18next';
import PriceEditRulesVatSelect from './PriceEditRulesVatSelect';
import { useState, Dispatch, SetStateAction, ChangeEvent, useEffect } from 'react';
import {
  PriceProductDTO,
  PriceProductRuleCategoryDTO,
  RuleApplication,
  RuleCurrency,
  RuleTarget,
  VatBase,
} from '@tiketti-team/tp-types-and-utils/types';
import { PriceEditRulesTargetApplicationSelect } from './PriceEditRulesTargetApplicationSelect';
import { PriceProductRuleTargetsAndApplicationProvider } from '../../context/prices/usePriceProductRuleTargetsAndApplications';

interface SaveEditedPriceProducts {
  setSaveEditedRules: Dispatch<SetStateAction<PriceProductDTO>>;
  saveEditedRules: PriceProductDTO;
}
interface NewRuleCategory {
  setNewRuleCategory: Dispatch<SetStateAction<PriceProductRuleCategoryDTO[]>>;
  newRuleCategory: PriceProductRuleCategoryDTO[];
}
const PriceEditAddScheduleRule = (props: SaveEditedPriceProducts & NewRuleCategory & { ruleCategoryId: number }) => {
  // WIP
  const [ruleInput, setRuleInput] = useState({
    amount: 0,
    alv: '',
  });
  const [calculatedALV, setCalculatedALV] = useState({
    alv: '',
  });
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setRuleInput({ ...ruleInput, [name]: value });
    setCalculatedALV({ ...calculatedALV, [name]: value });
  };
  const allRuleIds: number[] = props.saveEditedRules.ruleCategories.flatMap((ruleCategory) =>
    ruleCategory.rules.map((rule) => rule.id)
  );
  const [ruleId, setRuleId] = useState(0);

  useEffect(() => {
    let ruleId = 0;

    if (props.newRuleCategory.length === 1) {
      const maxExistingRuleId = Math.max(...allRuleIds, 0);
      ruleId = maxExistingRuleId + 1;
    } else if (props.newRuleCategory.length >= 2) {
      const existingCategoryIndex = props.newRuleCategory.findIndex(
        (ruleCategory) => ruleCategory.id === props.ruleCategoryId
      );

      if (existingCategoryIndex >= 1) {
        const previousCategory = props.newRuleCategory[existingCategoryIndex - 1];
        const maxExistingRuleId = Math.max(...previousCategory.rules.map((rule) => rule.id), 0);
        ruleId = maxExistingRuleId + 1;
      }
    }

    const newRule = {
      id: ruleId,
      amount: 0,
      application: 'FIXED_AMOUNT' as RuleApplication,
      approved: false,
      approvedAt: '',
      approvedByName: '',
      createdAt: '',
      createdByName: '',
      createdByUUID: '',
      currency: 'EUR' as RuleCurrency,
      deliveryTime: 0,
      modifiedAt: '',
      modifiedByName: '',
      modifiedByUUID: '',
      priceRangeId: 0,
      reviewComment: '',
      target: 'ORDER' as RuleTarget,
      validFrom: '',
      validTo: '',
      vatBase: 'VAT_0' as VatBase,
    };

    const updatedNewRuleCategory = props.newRuleCategory.map((ruleCategory) => {
      if (ruleCategory.id === props.ruleCategoryId) {
        const updatedRules = [...ruleCategory.rules, newRule];
        return { ...ruleCategory, rules: updatedRules };
      }
      return ruleCategory;
    });

    setRuleId(ruleId);
    props.setNewRuleCategory(updatedNewRuleCategory);
  }, []);

  return (
    <div className='xxs:w-full xxs:mb-5 col-span-4'>
      <p className='edit-rule-price-header'> {t('prices.priceDetail.priceCategoryRuleLabel')}</p>
      <div className='mr-3'>
        <div className='edit-rules-input-headers-rules smaller-screens-hidden-text'>
          <p className='lg:col-span-2 text-sm mb-2'>{t('prices.priceDetail.priceRulesLabel')}</p>
          <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
          <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
        </div>
        <div className='edit-rules-input-container-rules relative'>
          <PriceProductRuleTargetsAndApplicationProvider>
            <PriceEditRulesTargetApplicationSelect
              saveEditedRules={props.saveEditedRules}
              setSaveEditedRules={props.setSaveEditedRules}
              ruleCategories={{ id: props.ruleCategoryId, name: { fi: '' }, rules: [] }}
              rules={{
                id: ruleId,
                amount: 0,
                application: 'FIXED_AMOUNT',
                approved: false,
                approvedAt: '',
                approvedByName: '',
                createdAt: '',
                createdByName: '',
                createdByUUID: '',
                currency: 'EUR',
                deliveryTime: 0,
                modifiedAt: '',
                modifiedByName: '',
                modifiedByUUID: '',
                priceRangeId: 0,
                reviewComment: '',
                target: 'ORDER',
                validFrom: '',
                validTo: '',
                vatBase: 'VAT_0',
              }}
              ruleCategoryId={props.ruleCategoryId}
              newRuleId={ruleId}
              newRuleCategory={props.newRuleCategory}
              setNewRuleCategory={props.setNewRuleCategory}
            />
          </PriceProductRuleTargetsAndApplicationProvider>
          <div className='edit-rules-visible-small-screen'>
            <p className='text-sm'>{t('prices.priceDetail.amountLabel')}</p>
          </div>
          <div className='relative edit-rule-input-content-rules'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3 '>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input
              name='amount'
              value={ruleInput.amount}
              onChange={onChange}
              className='edit-rules-inputs-rules w-full'
            ></input>
          </div>
          <div className='edit-rules-visible-small-screen'>
            <p className='text-sm'>{t('prices.priceDetail.vatZeroPriceLabel')}</p>
          </div>
          <div className='relative edit-rule-input-content-rules'>
            <div className='pointer-events-none absolute inset-y-2 right-0 mr-3 '>
              <span className='text-gray-400 sm:text-sm'>{t('prices.priceRules.currency.EUR')}</span>
            </div>
            <input
              disabled
              name='alv'
              value={calculatedALV.alv}
              onChange={onChange}
              className='edit-rules-inputs-rules w-full'
            ></input>
          </div>
        </div>
        <PriceEditRulesVatSelect
          saveEditedRules={props.saveEditedRules}
          setSaveEditedRules={props.setSaveEditedRules}
          ruleCategories={{ id: 0, name: { fi: '' }, rules: [] }}
          rules={{
            id: 0,
            amount: 0,
            application: 'FIXED_AMOUNT',
            approved: false,
            approvedAt: '',
            approvedByName: '',
            createdAt: '',
            createdByName: '',
            createdByUUID: '',
            currency: 'EUR',
            deliveryTime: 0,
            modifiedAt: '',
            modifiedByName: '',
            modifiedByUUID: '',
            priceRangeId: 0,
            reviewComment: '',
            target: 'ORDER',
            validFrom: '',
            validTo: '',
            vatBase: 'VAT_0',
          }}
        />
      </div>
    </div>
  );
};
export default PriceEditAddScheduleRule;

import { useEffect } from 'react';
import { getPriceList } from '../../apis/PriceApi';
import usePrices from '../../context/prices/usePrices';
import ErrorData from '../../components/general/states/ErrorData';
import Loading from '../../components/general/states/Loading';
import TopInfo from '../general/TopInfo';

export default function PriceListTopInfo() {
  const {
    state: { pricelists, loading, error },
    dispatch,
  } = usePrices();

  useEffect(() => {
    let ignore = false;
    async function fetchPriceList() {
      dispatch({ type: 'FETCH_PRICE_LIST_BEGIN' });
      try {
        const payload = await getPriceList();

        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_LIST_SUCCESS', payload: { pricelists: payload.data } });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'FETCH_PRICE_LIST_ERROR', error: 'todo-error-message-from-server' });
        }
      }
    }
    fetchPriceList();
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorData error={error} />;
  }
  // Temporary
  const filterOneTopInfo = pricelists.filter((result) => {
    return result.id === 1;
  });

  return (
    <>
      {filterOneTopInfo.map((result) => (
        <div key={result.id}>
          <TopInfo modifiedByName={result.modifiedByName} modifiedAt={result.modifiedAt} />
        </div>
      ))}
    </>
  );
}
